<template>
	<v-app>
		<v-content>
			<v-container class="fill-height" fluid>
				<div class="rule">
          <h1>利用規約</h1>
          <dl class="dl-block">
            <p>
              この規約（以下「本規約」といいます）は、やまやコミュニケーションズ（以下「当社」といいます）が提供するスマートフォン（以下「端末機」といいます）向けの公式アプリ（以下「本アプリ」といいます）のすべてのサービス（以下「本サービス」といいます）の利用に関する条件を、本サービスを利用するお客様（以下「お客様」といいます）と当社との間で定めるものです。<br>
              お客様は、本規約内容に同意いただいた場合にのみ、本サービスをご利用いただけます。本アプリのダウンロード後ユーザー登録の際に、「利用規約に同意して始める」を押下した場合は、その時点で本利用規約の内容に同意いただいたものとみなします。本規約に同意頂けない場合は、直ちに本アプリをお客様の端末機から削除してください。<br>
              なお、14歳以下の方は、親権者等の法定代理人の事前の同意のもとに本アプリへの登録および本サービスの利用を行っていただけますようお願いいたします。
            </p>

            <dt>1. 本アプリのご利用にあたって</dt>
            <dd>
              お客様の端末機から本アプリに会員登録すると、本アプリをご利用いただけます。当社は、本サービスを、登録情報の有無、その他、当社が必要と判断する条件を満たしたお客様に限定して提供することができるものとします。本アプリは無料でご利用頂けますが、本サービスの利用にあたり、携帯電話端末からのアクセス、メッセージなどの受信に必要な通信費はお客様ご自身の負担となります。お客様が本アプリおよび本サービスの利用に際して行った一切の行為、その結果および当該行為によって被った損害について、何等の責任も負わないものとします。
            </dd>
            <dt>2. 個人情報の取り扱いについて</dt>
            <dd>
              当社および開発会社において、個人情報は、本サービスの開発・販売・運営業務における当社の正当な事業遂行上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。
            </dd>
            <dt>3. 知的財産権について</dt>
            <dd>
              本アプリおよび本サービスに関する一切の特許権、実用新案権、意匠権、著作権、商標権等の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。以下「知的財産権」といいます）は全て当社または開発会社または当該権利を有する第三者に帰属します。お客様は、本サービスを利用するにあたって、一切の権利を取得することはないものとし、当社は、お客様に対し、本サービスに関する知的財産権について、本サービスを本規約に従ってお客様の端末機においてのみ使用できる譲渡不能の権利として許諾するものです。したがって、お客様は個人で楽しむ目的（著作権法第30条第１項の範囲での私的使用）でのみ本アプリおよび本サービスを利用できます。また、お客様は本アプリについて当社から提供された状態でのみ利用するものとし、本アプリおよび本サービスに関する情報の全部または一部を、当社の了解を得ずに、複製、改変、貸与、配布などにより転用してはなりません。
            </dd>
            <dt>4. 禁止行為について</dt>
            <dd>
              お客様は、本規約の他の条項に定めるものの他、本サービス（本アプリを含みます。本条で以下同じ。）の利用にあたって、以下の行為またはそのおそれのある行為を行ってはならないものとします。
              <br> (1)他のお客様、当社または第三者に不利益または損害を与える行為
              <br> (2)公序良俗に反する行為
              <br> (3)法令に違反する行為
              <br> (4)当社の書面による事前の承認を得ずに、本サービスに関連して営利を追求する行為
              <br> (5)当社による本サービスの運営を妨害する行為
              <br> (6)本サービスの信用を失墜、毀損させる行為
              <br> (7)当社の承認した以外の方法で本サービスを利用する行為
              <br> (8)本サービスを譲渡、貸与、公衆送信、使用許諾する行為
              <br> (9)本サービスを複製、翻案、編集、改変、逆アセンブル、逆コンパイル、リバースエンジニアリングする行為
              <br> (10)コンピュータウイルス等を本アプリで利用して使用をしたり、第三者に提供する行為、あるいはそのおそれのある行為
              <br> (11)その他、当社が不適切と判断する行為
            </dd>
            <dt>5. 免責事項について</dt>
            <dd>
              当社は、本サービス（本アプリを含みます。本条で以下同じ）に関する情報およびデータの正常性、信頼性、正確性を保証するものではありません。本サービスにかかる情報によりお客様または第三者に損害が生じた場合であっても、その損害についていかなる責任も負いません。また、当社は、本サービスの利用に起因するソフトウェア、ハードウェア上の事故、お客様間またはお客様と第三者の間において生じたトラブル、その他の事故などによる全ての損害についても、いかなる責任も負いません。
            </dd>
            <dt>6. 損害賠償について</dt>
            <dd>
              本規約に違反して権利侵害等の問題が発生した場合、お客様は、自己の負担と責任においてかかる問題を解決するとともに、当社に何等の迷惑または損害を与えないものとし、仮に当社に損害を与えたときは、当社に対して当該損害の全てを賠償していただきます。
            </dd>
            <dt>7. お客様による本サービスの利用中止について</dt>
            <dd>
              本サービスのご利用の中止を希望される場合は、お客様自身で端末機から本アプリを削除して頂く必要があります。この場合、本アプリ内に保存された各種情報を削除するため、必ず本サービス上で退会の手続きを行ってから、本アプリを削除して下さい。登録情報の削除(退会)の手続を行わずに本アプリを削除(アンインストール)した場合には、サーバ上に保存している登録情報は削除されませんのでご注意ください。
            </dd>
            <dt>8. 当社による本サービスの提供中止について</dt>
            <dd>
              当社は、お客様が本規約に違反した場合、当該お客様に対して本サービスの提供を中止することがあるほか、天災地変等の不可抗力による場合、本サービスのシステムに支障を来たした場合、当社および開発会社（本サービスの提供に関する当社の委託先を含む）が必要と判断する場合、あらかじめお客様に通知することなく、いつでも、本サービス（本アプリを含みます。以下同じ）の全部または一部の内容の提供を中止することができるものとします。当該中止についてお客様または第三者に損害が生じた場合であっても、当社は、いかなる責任も負いません。
            </dd>
            <dt>9. 本サービスの変更・一時中断・廃止について</dt>
            <dd>
              当社は、本サービスの内容を必要に応じて変更することがあります。また、メンテナンスや内容更新のため本サービスの提供を一時中断、あるいは本サービスの廃止を行う場合があります。当該変更または一時中断、廃止によってお客様または第三者に損害が生じた場合であっても、当社は、いかなる責任も負いません。
            </dd>
            <dt>10. 本規約の改訂</dt>
            <dd>
              当社は、随時本規約を改訂できるものとします。当社は本規約を改訂した場合、その都度、改定後の本規約を本アプリ内に掲示することによってお客様に告知するものとし、改定後の本規約は当該掲示の時点で効力を生じるものとします。
            </dd>

            <dt>11. 準拠法、協議、管轄</dt>
            <dd>
              本規約の解釈および運用は日本法に準拠します。 本サービスに関連してお客様、当社ないし第三者との間で疑義、問題が生じた場合、その都度誠意をもって協議し、解決を図るものとします。 協議によっても疑義、問題が解決しない場合、これらに関する紛争は当社の本店所在地を管轄する地方裁判所または簡易裁判所を第1審の専属的合意管轄裁判所とします。
            </dd>

            <p class="si">附則<br> 本規約は2022年11月1日から実施します。
            </p>
          </dl>
				</div>

        <div class="text-center my-10 w100" v-if="history_length > 1">
          <v-btn rounded class="maincolor-bg default_button" @click="routerBack">戻る</v-btn>
        </div>
			</v-container>
		</v-content>
	</v-app>
</template>

<script>
export default {
  //20201208 NEW_DEV-733 A36-No.21 cyber 李 start
  props: {
    dialog_flg: {
      type: Boolean,
      default: false
    }
  },
	data() {
		return {
      shop_name: this.storageGet('shop_name'),
      history_length: null,
		}
  },
  created () {
    var len=history.length
    this.history_length = len

    if(this.dialog_flg){
      this.history_length = 0
    }
  }
  //20201208 NEW_DEV-733 A36-No.21 cyber 李 end
}
</script>

<style scoped>
h1 {
	font-family: YuGothic;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #333;
	margin-bottom: 20px;
}

.rule {
	padding-top: 10px;
}

dl {
	font-size: 13px;
}

dt {
	font-family: YuGothic;
	font-weight: bold;
	font-size: 16px;
	text-align: center;
	color: #333;
	margin: 30px 0 10px;
}

.si {
	margin-top: 30px;
}
</style>
