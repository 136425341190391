<template>
  <v-content>

    <!-- ベージック -->
    <div :class="bought_flg == 0 ? 'pa-3 ticket_imgbox type1 flexcenter' : 'pa-3 ticket_imgbox type1 flexcenter use'" v-if="item.img_type == 1" :style="{
                'background-size': 'contain',
                'background-image': 'url(' + item.img_path + ')',
                'background-postion': 'top center',
              }">
      <div class="pa-3 text-center">
        <img v-if="item.ticket_type_text == '1回券'" src="@/assets/digital_ticket/digitalticket_ticket_big.svg">
        <img v-else-if="item.ticket_type_text == '回数券'" src="@/assets/digital_ticket/digitalticket_number_big.svg" />
        <img v-else-if="item.ticket_type_text == '定期券'" src="@/assets/digital_ticket/digitalticket_teiki_big.svg" />
        <div class="text-center mb-4"><img src="@/assets/digital_ticket/tex_dt01.svg"></div>
        <h1 class="dth1">{{item.title}}</h1>
        <p class="mb-0 mt-12 fontsize16">{{item.comment_for_discount}}</p>
        <div class="price d-inline mr-2">
          {{item.price}}<span class="subtitle-2">円</span>
          <v-chip outlined x-small class="inline-block my-0 mx-2 px-2" color="#000">
            税込
          </v-chip>
        </div>

        <p v-if="item.list_price" class="mb-0 mt-0 fontsize11">通常価格 {{item.list_price}}円</p>

        <v-chip v-if="item.expiration_text" x-small class="my-0 mx-2 px-4 py-1 font-weight-bold" color="#F6FF8E" text-color="#333333">
          {{item.expiration_text}}
        </v-chip>
      </div>
    </div>

    <!-- ガーリー -->
    <div :class="bought_flg == 0 ? 'pa-3 ticket_imgbox type2 flexcenter' : 'pa-3 ticket_imgbox type2 flexcenter use'" v-if="item.img_type == 2" :style="{
                'background-size': 'cover',
                'background-image': 'url(' + item.img_path + ')',
              }">
      <div class="pa-3 text-center">
        <img v-if="item.ticket_type_text == '1回券'" src="@/assets/digital_ticket/digitalticket_ticket_big.svg">
        <img v-else-if="item.ticket_type_text == '回数券'" src="@/assets/digital_ticket/digitalticket_number_big.svg" />
        <img v-else-if="item.ticket_type_text == '定期券'" src="@/assets/digital_ticket/digitalticket_teiki_big.svg" />
        <div class="text-center mt-2 mb-4"><img src="@/assets/digital_ticket/tex_dt04.svg"></div>
        <h1 class="dth1">{{item.title}}</h1>
        <v-divider class="my-3 mx-8" color="#333" style="max-height:2px;"></v-divider>
        <p class="mb-0 mt-4 fontsize16">{{item.comment_for_discount}}</p>
        <div class="price d-inline mr-2">
          {{item.price}}<span class="subtitle-2">円</span>
          <v-chip outlined x-small class="inline-block my-0 mx-2 px-2" color="#000">
            税込
          </v-chip>
        </div>

        <p v-if="item.list_price" class="mb-0 mt-0 fontsize11">通常価格 {{item.list_price}}円</p>

        <v-chip v-if="item.expiration_text" x-small class="my-0 mx-2 px-4 py-1 font-weight-bold" color="#fff" text-color="#333333">
          {{item.expiration_text}}
        </v-chip>
      </div>
    </div>

    <!-- シンプル -->
    <div :class="bought_flg == 0 ? 'pa-3 ticket_imgbox type3' : 'pa-3 ticket_imgbox type3 use'" v-if="item.img_type == 3" :style="{
                'background-size': 'cover',
                'background-image': 'url(' + item.img_path + ')',
              }">
      
      <div class="pa-3 text-center whiteback">
        <div>
        <img v-if="item.ticket_type_text == '1回券'" src="@/assets/digital_ticket/digitalticket_ticket_big.svg">
        <img v-else-if="item.ticket_type_text == '回数券'" src="@/assets/digital_ticket/digitalticket_number_big.svg" />
        <img v-else-if="item.ticket_type_text == '定期券'" src="@/assets/digital_ticket/digitalticket_teiki_big.svg" />
        <div class="text-center mb-4"><img src="@/assets/digital_ticket/tex_dt01.svg"></div>
        <h1 class="dth1">{{item.title}}</h1>
        <v-divider class="my-3"></v-divider>
        <p class="mb-0 mt-0 fontsize16">{{item.comment_for_discount}}</p>
        <div class="price d-inline mr-2">
          {{item.price}}<span class="subtitle-2">円</span>
          <v-chip outlined x-small class="inline-block my-0 mx-2 px-2" color="#000">
            税込
          </v-chip>
        </div>

        <p v-if="item.list_price" class="mb-0 mt-0 fontsize11">通常価格 {{item.list_price}}円</p>

        <v-chip v-if="item.expiration_text" x-small class="my-0 mx-2 px-4 py-1 font-weight-bold" color="#595757" text-color="white">
          {{item.expiration_text}}
        </v-chip>
        </div>
      </div>
    </div>

    <!-- スタイリッシュ -->
    <div :class="bought_flg == 0 ? 'pa-3 ticket_imgbox type4' : 'pa-3 ticket_imgbox type4 use'" v-if="item.img_type == 4" :style="{
                'background-size': 'cover',
                'background-image': 'url(' + item.img_path + ')',
              }">
      
      <div class="pa-3 text-center whiteback2">
        <div>
        <img v-if="item.ticket_type_text == '1回券'" src="@/assets/digital_ticket/digitalticket_ticket_big.svg">
        <img v-else-if="item.ticket_type_text == '回数券'" src="@/assets/digital_ticket/digitalticket_number_big.svg" />
        <img v-else-if="item.ticket_type_text == '定期券'" src="@/assets/digital_ticket/digitalticket_teiki_big.svg" />
        <div class="text-center mb-4"><img src="@/assets/digital_ticket/tex_dt03.svg"></div>
        <h1 class="dth1">{{item.title}}</h1>
        <v-divider class="my-3 mx-8" color="#333" style="max-height:2px;"></v-divider>
        <p class="mb-0 mt-0 fontsize16">{{item.comment_for_discount}}</p>
        <div class="price d-inline mr-2">
          {{item.price}}<span class="subtitle-2">円</span>
          <v-chip outlined x-small class="inline-block my-0 mx-2 px-2 font-weight-bold" color="#000">
            税込
          </v-chip>
        </div>

        <p v-if="item.list_price" class="mb-0 mt-0 fontsize11">通常価格 {{item.list_price}}円</p>

        <v-chip v-if="item.expiration_text" x-small class="my-0 mx-2 px-4 py-1" color="#fff" text-color="#333">
          {{item.expiration_text}}
        </v-chip>
        </div>
      </div>
    </div>


  </v-content>
</template>

<script>
export default {
  props: ['item', 'bought_flg'],
  data() {
    return {
      img_path: require("@/assets/digital_ticket/bg_simple@2x.png"),
    };
  },
}
</script>

<style scoped>
.dth1 {
  font-size: 24px;
  line-height: 1.5;
}

.price {
  font-size: 32px;
}
.pricedetail {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Roboto';
}

.type1, .type2 {
  border-radius: 15px;
  width: 92%;
  margin: 0 auto 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  min-height: 363px;
}
.type2, .type4 {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}

.type3 {
  height: 363px;
  position: relative;
  margin: 0 auto 20px;
}

.type1.use, .type2.use {
  border-radius: 15px 15px 0 0;
  margin: 0 auto;
}
.type3.use, .type4.use {
  border-radius: 15px 15px 0 0;
  width: 92%;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
}

.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.whiteback {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  min-height: 100%;
  background-color: rgba(255,255,255,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.whiteback2 {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  min-height: 100%;
  background-color: rgba(255,234,193,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>