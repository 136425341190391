var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[(_vm.item.img_type == 1)?_c('div',{class:_vm.bought_flg == 0 ? 'pa-3 ticket_imgbox type1 flexcenter' : 'pa-3 ticket_imgbox type1 flexcenter use',style:({
              'background-size': 'contain',
              'background-image': 'url(' + _vm.item.img_path + ')',
              'background-postion': 'top center',
            })},[_c('div',{staticClass:"pa-3 text-center"},[(_vm.item.ticket_type_text == '1回券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_ticket_big.svg")}}):(_vm.item.ticket_type_text == '回数券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_number_big.svg")}}):(_vm.item.ticket_type_text == '定期券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_teiki_big.svg")}}):_vm._e(),_c('div',{staticClass:"text-center mb-4"},[_c('img',{attrs:{"src":require("@/assets/digital_ticket/tex_dt01.svg")}})]),_c('h1',{staticClass:"dth1"},[_vm._v(_vm._s(_vm.item.title))]),_c('p',{staticClass:"mb-0 mt-12 fontsize16"},[_vm._v(_vm._s(_vm.item.comment_for_discount))]),_c('div',{staticClass:"price d-inline mr-2"},[_vm._v(" "+_vm._s(_vm.item.price)),_c('span',{staticClass:"subtitle-2"},[_vm._v("円")]),_c('v-chip',{staticClass:"inline-block my-0 mx-2 px-2",attrs:{"outlined":"","x-small":"","color":"#000"}},[_vm._v(" 税込 ")])],1),(_vm.item.list_price)?_c('p',{staticClass:"mb-0 mt-0 fontsize11"},[_vm._v("通常価格 "+_vm._s(_vm.item.list_price)+"円")]):_vm._e(),(_vm.item.expiration_text)?_c('v-chip',{staticClass:"my-0 mx-2 px-4 py-1 font-weight-bold",attrs:{"x-small":"","color":"#F6FF8E","text-color":"#333333"}},[_vm._v(" "+_vm._s(_vm.item.expiration_text)+" ")]):_vm._e()],1)]):_vm._e(),(_vm.item.img_type == 2)?_c('div',{class:_vm.bought_flg == 0 ? 'pa-3 ticket_imgbox type2 flexcenter' : 'pa-3 ticket_imgbox type2 flexcenter use',style:({
              'background-size': 'cover',
              'background-image': 'url(' + _vm.item.img_path + ')',
            })},[_c('div',{staticClass:"pa-3 text-center"},[(_vm.item.ticket_type_text == '1回券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_ticket_big.svg")}}):(_vm.item.ticket_type_text == '回数券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_number_big.svg")}}):(_vm.item.ticket_type_text == '定期券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_teiki_big.svg")}}):_vm._e(),_c('div',{staticClass:"text-center mt-2 mb-4"},[_c('img',{attrs:{"src":require("@/assets/digital_ticket/tex_dt04.svg")}})]),_c('h1',{staticClass:"dth1"},[_vm._v(_vm._s(_vm.item.title))]),_c('v-divider',{staticClass:"my-3 mx-8",staticStyle:{"max-height":"2px"},attrs:{"color":"#333"}}),_c('p',{staticClass:"mb-0 mt-4 fontsize16"},[_vm._v(_vm._s(_vm.item.comment_for_discount))]),_c('div',{staticClass:"price d-inline mr-2"},[_vm._v(" "+_vm._s(_vm.item.price)),_c('span',{staticClass:"subtitle-2"},[_vm._v("円")]),_c('v-chip',{staticClass:"inline-block my-0 mx-2 px-2",attrs:{"outlined":"","x-small":"","color":"#000"}},[_vm._v(" 税込 ")])],1),(_vm.item.list_price)?_c('p',{staticClass:"mb-0 mt-0 fontsize11"},[_vm._v("通常価格 "+_vm._s(_vm.item.list_price)+"円")]):_vm._e(),(_vm.item.expiration_text)?_c('v-chip',{staticClass:"my-0 mx-2 px-4 py-1 font-weight-bold",attrs:{"x-small":"","color":"#fff","text-color":"#333333"}},[_vm._v(" "+_vm._s(_vm.item.expiration_text)+" ")]):_vm._e()],1)]):_vm._e(),(_vm.item.img_type == 3)?_c('div',{class:_vm.bought_flg == 0 ? 'pa-3 ticket_imgbox type3' : 'pa-3 ticket_imgbox type3 use',style:({
              'background-size': 'cover',
              'background-image': 'url(' + _vm.item.img_path + ')',
            })},[_c('div',{staticClass:"pa-3 text-center whiteback"},[_c('div',[(_vm.item.ticket_type_text == '1回券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_ticket_big.svg")}}):(_vm.item.ticket_type_text == '回数券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_number_big.svg")}}):(_vm.item.ticket_type_text == '定期券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_teiki_big.svg")}}):_vm._e(),_c('div',{staticClass:"text-center mb-4"},[_c('img',{attrs:{"src":require("@/assets/digital_ticket/tex_dt01.svg")}})]),_c('h1',{staticClass:"dth1"},[_vm._v(_vm._s(_vm.item.title))]),_c('v-divider',{staticClass:"my-3"}),_c('p',{staticClass:"mb-0 mt-0 fontsize16"},[_vm._v(_vm._s(_vm.item.comment_for_discount))]),_c('div',{staticClass:"price d-inline mr-2"},[_vm._v(" "+_vm._s(_vm.item.price)),_c('span',{staticClass:"subtitle-2"},[_vm._v("円")]),_c('v-chip',{staticClass:"inline-block my-0 mx-2 px-2",attrs:{"outlined":"","x-small":"","color":"#000"}},[_vm._v(" 税込 ")])],1),(_vm.item.list_price)?_c('p',{staticClass:"mb-0 mt-0 fontsize11"},[_vm._v("通常価格 "+_vm._s(_vm.item.list_price)+"円")]):_vm._e(),(_vm.item.expiration_text)?_c('v-chip',{staticClass:"my-0 mx-2 px-4 py-1 font-weight-bold",attrs:{"x-small":"","color":"#595757","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.item.expiration_text)+" ")]):_vm._e()],1)])]):_vm._e(),(_vm.item.img_type == 4)?_c('div',{class:_vm.bought_flg == 0 ? 'pa-3 ticket_imgbox type4' : 'pa-3 ticket_imgbox type4 use',style:({
              'background-size': 'cover',
              'background-image': 'url(' + _vm.item.img_path + ')',
            })},[_c('div',{staticClass:"pa-3 text-center whiteback2"},[_c('div',[(_vm.item.ticket_type_text == '1回券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_ticket_big.svg")}}):(_vm.item.ticket_type_text == '回数券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_number_big.svg")}}):(_vm.item.ticket_type_text == '定期券')?_c('img',{attrs:{"src":require("@/assets/digital_ticket/digitalticket_teiki_big.svg")}}):_vm._e(),_c('div',{staticClass:"text-center mb-4"},[_c('img',{attrs:{"src":require("@/assets/digital_ticket/tex_dt03.svg")}})]),_c('h1',{staticClass:"dth1"},[_vm._v(_vm._s(_vm.item.title))]),_c('v-divider',{staticClass:"my-3 mx-8",staticStyle:{"max-height":"2px"},attrs:{"color":"#333"}}),_c('p',{staticClass:"mb-0 mt-0 fontsize16"},[_vm._v(_vm._s(_vm.item.comment_for_discount))]),_c('div',{staticClass:"price d-inline mr-2"},[_vm._v(" "+_vm._s(_vm.item.price)),_c('span',{staticClass:"subtitle-2"},[_vm._v("円")]),_c('v-chip',{staticClass:"inline-block my-0 mx-2 px-2 font-weight-bold",attrs:{"outlined":"","x-small":"","color":"#000"}},[_vm._v(" 税込 ")])],1),(_vm.item.list_price)?_c('p',{staticClass:"mb-0 mt-0 fontsize11"},[_vm._v("通常価格 "+_vm._s(_vm.item.list_price)+"円")]):_vm._e(),(_vm.item.expiration_text)?_c('v-chip',{staticClass:"my-0 mx-2 px-4 py-1",attrs:{"x-small":"","color":"#fff","text-color":"#333"}},[_vm._v(" "+_vm._s(_vm.item.expiration_text)+" ")]):_vm._e()],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }