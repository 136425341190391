<template>
    <div>
        <v-dialog v-model="compdialog" persistent>
            <v-card class="ticket_dialogue px-1">
                <h3 class="maincolor ticket_dialogue_title">チケット使用認証</h3>
                <p class="ticket_dialogue_text px-2">店舗の管理アプリにてQRコードを読み取ってもらうか、読み取りができない場合はこの画面をスタッフに提示してください</p>

                <div class="qrblc">
                    <qrcode :value="qrcodeurl" :options="{ width: 135, margin: 0 }"></qrcode>
                </div>

                <p class="authcode_text mb-5">認証コードを使用する場合はスタッフに提示してください</p>
                <v-form ref="form" @submit.prevent="submit">
                    <p class="auth_field_support">認証コードを入力してください</p>
                    <v-text-field style="text-align:center;" class="authfield px-12" v-model="authcode" :error-messages="authcodeErrors" maxlength="4" type="tel" label="認証コードを入力" required @input="$v.authcode.$touch()" @blur="$v.authcode.$touch()"></v-text-field>
                    <div class="text-center pt-1 pb-4">
                        <v-btn rounded :disabled="!authcode" class="maincolor-bg default_button" @click.stop="use()">チケットを使用する</v-btn>
                    </div>
                    <div class="text-center pb-6">
                        <v-btn small rounded class="graybtn" @click="close">キャンセルする</v-btn>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],
    validations: {
        authcode: { required, maxLength: maxLength(4) },
    },
    props: ['data', 'init'],

    data: () => ({
        compdialog: false,
        authcode: '',
        ticket_id: null,
        authcode_flg: null,
        qrcodeurl: null,
        item: null,
    }),

    computed: {
        authcodeErrors() {
            const errors = []
            if (!this.$v.authcode.$dirty) return
            errors
            !this.$v.authcode.maxLength && errors.push('認証コードが違います')
            !this.$v.authcode.required && errors.push('認証コードを入力してください')
            return errors
        },
    },

    methods: {
        use() {
            this.compdialog = false
            this.$emit('use', this.authcode)
        },
        open(id, flg, qr_url, item) {
          this.item = item
          this.ticket_id = id
          this.authcode_flg = flg
          //this.qrcodeurl = "" + id + "" + this.init.uid //仕様きまってから変更
          this.qrcodeurl = qr_url
          this.compdialog = true
        },
        close() {
          this.compdialog = false
          this.$emit('pollingStop')
        },
        submit() {
          this.$v.$touch()
        },
        clear() {
            this.$v.$reset()
            this.authcode = ''
        },
    },
}
</script>

<style scoped>
/* override */

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 15px;
}

.ticket_dialogue {
    padding: 16px;
}

.ticket_dialogue_title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 0 0 15px;
}

.ticket_dialogue_text {
    font-size: 13px;
    line-height: 20px;
    color: #333333;
}

.qrblc {
    width: 60%;
    margin: 0 auto 20px;
    padding: 20px;
    background-color: #F9F9F9;
}

.authcode_text {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin: 0 0 0;
}

.auth_field_support {
  font-size: 12px;
  text-align: center;
  color: #707070;
  margin: 0 0 0;
}

.authfield>>>label {
    font-size: 13px;
    text-align: center;
}

.authfield>>>input {
    text-align: center;
}
</style>
