<template>
  <v-content>
    <v-card class="overflow-hidden mb-7" style="min-height:168px;" v-for="(item, i) in data" :key="i" @click.stop="routerBindTo({ name: 'digital_buy', query: { ticket_id: item.id, history_id: item.history_id }})">
      <v-row class="" no-gutters align="center">
        <v-col cols="3" class="text-center ma-0 pa-0">
          <div class="">
            <div class="">
              <img src="@/assets/digital_ticket/digitalticket_ticket.svg" />
            </div>
            <v-chip outlined x-small class="ma-0 px-2" color="#9d9d9d">
              {{item.ticket_type_text}}
            </v-chip>
          </div>
        </v-col>
          <v-col
            class="pa-3"
            cols="9"
            v-bind:style="{
              'background-size': 'cover',
              'background-image': 'url(' + item.image_path + ')',
              'position': 'relative',
              'min-height': '168px',
              'align-items': 'center',
              'display': 'flex',
            }"
          >
          <v-overlay :absolute="true" opacity="0.65" v-if="item.img_type == 0" z-index="2">
          </v-overlay>
          <div :class="item.img_type == 0 ? 'imgtype0' : ''" style="z-index: 3; position:relative;">
          <p class="subtitle-2 font-weight-bold mb-0">
              {{item.title}}
          </p>
          <p class="fontsize9 pt-2 mb-2" style="line-height: 0.8rem;">
            {{item.terms_of_use_text}}
          </p>
          <v-btn rounded depressed class="maincolor-bg default_button w90" @click.stop="routerBindTo({ name: 'digital_buy', query: { ticket_id: item.id, history_id: item.history_id }})">チケットを使用する</v-btn>
          </div>
        </v-col>
      </v-row>

      <v-overlay absolute opacity="0.7" v-if="item.is_use == 1">
        <div class="text-center">
          <p class="flgtext">{{item.use_date}}に使用済み</p>
        </div>
        <div class="text-center pt-0">
          <v-row justify="center">
            <v-col cols="6">
              <v-btn rounded class="graybtn" width="100%" @click.stop="onOpenDialog(item.id, item.history_id, i)">削除する</v-btn>
            </v-col>
            <v-col cols="6" v-if="item.repurchase === 1">
              <v-btn rounded class="maincolor-btn2 fontsize13" width="100%" @click="routerBindTo({ name: 'digital_detail', query: { ticket_id: item.id }})">再度購入する</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-overlay>

      <v-overlay absolute opacity="0.7" v-else-if="!item.is_expiration">
        <div class="text-center">
          <img class="img" src="@/assets/sandtimer.svg" width="30">
          <p class="flgtext fontsize13">有効期限切れ</p>
        </div>
        <div class="text-center pt-1">
          <v-btn rounded class="graybtn default_button w70" @click.stop="onOpenDialog(item.id, item.history_id, i)">削除する</v-btn>
        </div>
      </v-overlay>

    </v-card>

    <!-- InfiniteLoading -->
    <infinite-loading ref="infiniteLoading"  @infinite="infiniteHandler" spinner="spiral">
      <div slot="spinner"><v-progress-circular :size="30" :width="2" class="maincolor" indeterminate></v-progress-circular></div>
      <div slot="no-more"></div>
      <!-- 20201203 NEW_DEV-733 A35-No.17 cyber 李 start -->
      <div slot="no-results">現在までに購入されたチケットはありません</div>
      <!-- 20201203 NEW_DEV-733 A35-No.17 cyber 李 end -->
    </infinite-loading>

    <v-dialog v-model="dialog" persistent>
      <v-card class="ticket_dialogue text-center pa-5">
        <p class="body-2 mb-10">チケットを削除しますか？</p>
        <div class="text-center">
          <v-btn rounded class="maincolor-bg default_button" @click.stop="onDeleteTicket">削除する</v-btn>
          <br><br>
          <v-btn rounded class="caption font-weight-black mb-10" style="background-color:#EFEFEF;" @click="dialog = false">キャンセルする</v-btn>
        </div>
      </v-card>
    </v-dialog>

  </v-content>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    InfiniteLoading,
  },
  props: ['init'],
  data () {
    return {
      page: 1,
      result: null,
      data: null,
      model: null,
      dialog: false,
      ticket_id: null,
      history_id: null,
      ticket_index: null,
    }
  },
  methods: {
      async infiniteHandler() {
          var shop_id = this.getUrlGetParam('shop_id');

          var params = {
              'page': this.page,
              'is_bought': 1
          }

          if (shop_id) {
            params['shop_id'] = shop_id
          }

          var req = { params: params }

          var res = await this.apiCall('/digitalticket/list', req)

          if (res.data && res.data.length > 0) {
            if (this.page == 1) {
                this.result = res
                this.data = res.data
            } else {
                this.data.push(res.data);
            }
            this.page = this.page + 1
            this.$refs.infiniteLoading.stateChanger.loaded()
          } else {
            this.$refs.infiniteLoading.stateChanger.complete()
          }
      },
      onOpenDialog(id, history_id, index) {
        this.ticket_id = id
        this.history_id = history_id
        this.ticket_index = index
        this.dialog = true
      },
      async onDeleteTicket() {
        var req = {
          'id': this.history_id,
        }

        var res = await this.apiCall('/digitalticket/ticket-delete', req, 'post', 0)
        if (!res) return false;

        this.$delete(this.data, this.ticket_index);
        this.dialog = false;
      },
  },
}
</script>

<style>
.v-overlay__content {
    width: 90% !important;
}
.imgtype0 {
  color: #fff;
}
</style>
