<template>
  <v-content class="digital_ticket pb-12" v-if="item != null" id="js-scroll-top">
    <SelectedShop v-if="init.branch_flg == 1" :selected_shop_name="selected_shop" :router_param="{ name: 'digital_ticket_shops', query: { feature_id: 'digital_list'}}" ></SelectedShop>

    <h1 class="mt-8 text-center fontsize18 font-weight-bold">購入済みチケット<span v-if="item.userPaidTicketHistory.status == 2"> : 解約済み</span></h1>

    <div class="mb-5" style="width:50%; margin:0 auto;">
      <v-divider class="my-2 border_maincolor"></v-divider>
    </div>

    <div class="ticketWrap">
        <v-overlay :absolute="true" opacity="0.65" v-if="!item.is_expiration" z-index="10">
          <div class="text-center">
            <img class="img" src="@/assets/sandtimer.svg" width="30">
            <p class="flgtext fontsize13">有効期限切れ</p>
          </div>
        </v-overlay>
      <div class="img_wrap">
        <TicketDesign :item="item" :bought_flg="1" v-if="item.img_type != 0"></TicketDesign>
        <div v-else class="use">
          <v-img v-if="item.image_path" :src="item.image_path" lazy-src="@/assets/noimg@2x.png" class="noleftrounded" width="100%">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-img v-else src="@/assets/noimg@2x.png" lazy-src="@/assets/noimg@2x.png" class="noleftrounded"  width="100%">
          </v-img>
        </div>
      </div>

      <div class="ticket_detail py-8 px-4">
        <p class="caption text-center mb-8" @click="detailDialog = true">
          <v-icon small color="black">fas fa-info-circle</v-icon> チケット詳細・注意事項について
        </p>

        <!-- １回券・定期券のチケット使用ボタン -->
        <div v-if="item.ticket_type == 1" class="text-center">
          <v-btn v-if="item.limit_use_count > 0 && item.limit_use_count != item.use_count" rounded depressed class="maincolor-bg default_button w80 py-5" @click.stop="openUseDialog(item)">チケットを使用する</v-btn>
          <v-btn v-else disabled rounded depressed class="maincolor-bg default_button w80">チケットを使用する</v-btn>
        </div>
        <!-- 回数券のチケット使用ボタン -->
        <div v-if="item.ticket_type == 2" class="mt-6 text-center">
          <p class="limittext body-1">残り <span class="maincolor font-weight-bold"><span class="bignum">{{item.number_use - item.userPaidTicketHistory.number_use}}</span>回／{{item.number_use}}回</span><br>使用できます</p>
        </div>
        <!-- 定期券のチケット使用ボタン -->
        <div v-if="item.ticket_type == 3" class="text-center">
          <v-btn v-if="item.limit_availability_type != 2 || (item.limit_availability_type == 2 && item.limit_use_count > 0)" rounded depressed class="maincolor-bg default_button w80 py-5" @click.stop="openUseDialog(item)">チケットを使用する</v-btn>
          <v-btn v-else disabled rounded depressed class="maincolor-bg default_button w80">チケットを使用する</v-btn>
        </div>

        <!-- 定期券の解約ボタン -->
        <div v-if="item.ticket_type == 3 && item.userPaidTicketHistory.status == 1" class="mt-6 text-center">
          <v-btn rounded depressed class="graybtn default_button w60 btn_small" @click="routerBindTo({ name: 'digital_cancel', query: { ticket_id: item.id, expiration_text: item.expiration_text, stripe_id: item.userPaidTicketHistory.stripe_id, shop_id: item.shop_id }})">解約する</v-btn>
        </div>
      </div>

      <!-- 回数券の表示 -->
      <link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1" rel="stylesheet" type="text/css">
      <div class="number_ticket_wrap" v-if="item.ticket_type == 2">
        <div class="ticket_block" :class="{ opened: isOpen }" v-for="(card, index) in cards" :key="index" :id="index" :style="card.style">
          <transition
            name="custom-classes-transition"
            enter-active-class="animated tada"
            leave-active-class="animated bounceOutDown"
          >
            <div class="ticket_block" v-if="!card.lastflg || show">
              <div class="ticket_number">
                <div>
                  <p class="string_ticekt">Ticket</p>
                  <p class="ticketnum">{{card.num}}</p>
                </div>
              </div>
              <div class="ticket_content">
                <div class="w100">
                  <p class="ticketcard_title">{{item.title}}</p>
                  <div class="text-center">
                    <v-btn v-if="card.lastflg == 1" id="available" rounded depressed class="maincolor-bg btn_small default_button w80 py-5" @click.stop="openUseDialog(item)">チケットを使用する</v-btn>
                    <v-btn v-else rounded depressed disabled class="maincolor-bg btn_small default_button w80 py-5">チケットを使用する</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!-- 回数券の開閉ボタン -->
      <!-- 20201224 NEW_DEV-733 A36-No.60 cyber 李 start -->
      <div class="opencards text-center" id="openclose" v-if="item.ticket_type == 2 && item.number_use - item.userPaidTicketHistory.number_use > 0">
        <!-- 20201224 NEW_DEV-733 A36-No.60 cyber 李 end -->
        <v-btn v-if="!isOpen" class="maincolor-bg openbtn fontsize14" @click="OpenCard()"><v-icon x-small>fas fa-chevron-down</v-icon> 回数券チケットを開く</v-btn>
        <v-btn v-else class="graybtn openbtn close fontsize14" @click="CloseCard()"><v-icon x-small>fas fa-chevron-up</v-icon> 回数券チケットを閉じる</v-btn>
      </div>
    </div>

    <!-- チケット詳細ダイアログ -->
    <v-dialog v-model="detailDialog" height="522">
      <v-card class="pa-4">
        <h4 class="caption mb-5">チケット詳細</h4>
        <div class="detailinner px-4 mb-12">
          <v-chip outlined x-small class="inline-block my-0 px-2" color="#9D9D9D" text-color="#9D9D9D">{{item.ticket_type_text}}タイプ</v-chip>
          <h3 class="mb-0 mt-0 tickettitle">{{item.title}}</h3>
          <v-divider class="my-3 border_maincolor"></v-divider>
          <v-row align="center">
            <v-col cols="3" class="fontsize11 pb-0" style="color: #9D9D9D;">
              割引価格
            </v-col>
            <v-col cols="9" class="fontsize13 pb-0">
              <span class="pricedetail">{{item.price}}</span>円(税込)
            </v-col>
            <v-col cols="3" class="fontsize11" style="color: #9D9D9D;">
            </v-col>
            <v-col cols="9" class="fontsize13 py-0">
              通常価格 {{item.list_price}}円(税込)
            </v-col>
            <v-col cols="3" class="fontsize11" style="color: #9D9D9D;" v-show="item.public_start_date || item.public_end_date">
              販売期間
            </v-col>
            <v-col cols="9" class="fontsize13" v-show="item.public_start_date || item.public_end_date">
              {{item.public_start_date}}〜{{item.public_end_date}}
            </v-col>
            <v-col cols="3" class="fontsize11" style="color: #9D9D9D;" v-show="item.pay_timing != null">
              料金請求間隔
            </v-col>
            <v-col cols="9" class="fontsize13" v-show="item.pay_timing != null">
              {{item.pay_timing}}
            </v-col>
            <v-col cols="3" class="fontsize11" style="color: #9D9D9D;" v-show="item.limit_type_text">
              {{item.limit_type_text}}
            </v-col>
            <v-col cols="9" class="fontsize13" v-show="item.limit_type_text">
              {{item.limit_number}}名様限定
            </v-col>
            <v-col cols="3" class="fontsize11" style="color: #9D9D9D;">
              利用回数
            </v-col>
            <v-col cols="9" class="fontsize13">
              {{item.limit_use_count}}回
            </v-col>
            <v-col cols="3" class="fontsize11" style="color: #9D9D9D;">
              有効期限
            </v-col>
            <v-col cols="9" class="fontsize13">
              {{item.expiration_text}}
            </v-col>
            <v-col cols="3" class="fontsize11" style="color: #9D9D9D;" v-show="item.attention_text">
              注意事項
            </v-col>
            <v-col cols="9" class="fontsize13" v-show="item.attention_text">
              <!-- 20201210 NEW_DEV-733 A36-No.65 cyber 李 start -->
              <p v-html="item.attention_text"></p>
              <!-- 20201210 NEW_DEV-733 A36-No.65 cyber 李 end -->
            </v-col>
          </v-row>
        </div>

        <div class="text-center">
          <v-btn rounded depressed class="caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click="detailDialog = false">閉じる</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <appDialogue ref="appDialogue" @use="use"></appDialogue>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

  </v-content>

</template>

<script>
import appDialogue from '../../components/ticket_dialogue.vue'
import SelectedShop from '@/components/selected_shop.vue'
import TicketDesign from '@/views/digital_ticket/ticket_design.vue'
export default {
  components: {
    appDialogue,
    SelectedShop,
    TicketDesign,
  },
  data() {
    return {
      show: true,
      init: [],
      page: 1,
      result: null,
      data: null,
      model: null,
      maincolor: this.storageGet('color_code'),
      selected_shop: "全て",
      item: null,
      loading: false,
      cards: [],
      opencard: false,
      isOpen: false,
      detailDialog: false,
    };
  },
  watch: {
    item: {
      deep: true,
      handler: function () {
        //20201210 NEW_DEV-733 A36-No.60 cyber 李 start
        if (this.item.is_use == 1) {
          //20201210 NEW_DEV-733 A36-No.60 cyber 李 end
          this.show = false;
        }
      }
    }
  },
  methods: {
    refToParentOpen() {
        this.$refs.appDialogue.open()
    },
    async openUseDialog(item) {

      var qr_url = "useTicket://?id=" + item.id + "&device_uid=" + this.init.device_uid + "&type=4" + "&os=" + this.init.os + "&device_id=" + this.init.device_id + "&sid=" + this.init.sid + "&history_id=" + this.init.history_id

      // ダイアログ表示
      this.$refs.appDialogue.open(item.id, item.auth_flg, qr_url);

      var count = 0
      var baseData = this.storageGet('*')
      var apiCall  = this.apiCall
      var router = this.$router
      var callDialog = this.callDialog
      var mogiri = this.mogiri
      var dialog = this.$refs.appDialogue
      const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      var id = setInterval(function(){
          useCheck();
          if (count >= 15){
              clearInterval(id);
          }
      }, 1500);

      var useCheck = async function() {
        console.log("checking...")
        count++
        try{
          // デジタルチケット詳細API
          var digital_ticket_params = { params:{
            'ticket_id': baseData.ticket_id,
            'history_id': baseData.history_id,
          }}

          var res = await apiCall('/digitalticket/detail', digital_ticket_params);
          if (res.userPaidTicketHistory.number_use != item.userPaidTicketHistory.number_use) {
              dialog.close()
              clearInterval(id)
              await _sleep(300);
              mogiri() // mogiriメソッドが存在せずエラー発生したためこのソースないに一旦空メソッド作成しておきます
              await _sleep(1000);
              router.go({path: router.currentRoute.path, force: true});
          }

          if (!res) return false;
        } catch(e) {
            callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
        }
      }
    },
    async use(code) {
      try {
        //loadingを表示
        this.loading = true
        //念の為もう一度ローカルから取得（後ほど処理変える）
        this.init = this.storageGet('*')

        var params = {
          'id': this.init.ticket_id,
          'history_id': this.init.history_id,
          'code': code,
          'type': "4",
          'shop_id': this.item.shop_id,
        }
        var res = await this.apiCall('/user/ticket/use', params, 'post', 0);
        if (!res) {
          this.loading = false;
          return false
        }

        this.$set(this, 'result', res);
      } catch(e) {
        this.loading = false
        this.callDialog('エラー', 'エラーが発生いたしました。', 3);
      }
    },
    OpenCard: function(){
      this.isOpen = true
      setTimeout(this.toScroll, 50)
    },
    CloseCard: function(){
      this.isOpen = false
      setTimeout(this.toScroll, 50)
    },
    toScroll: function () {
      const element = document.getElementById("openclose")
      const rect = element.getBoundingClientRect()
      const currentScrolledHeight = window.pageYOffset || document.documentElement.scrollTop
      const position = window.innerHeight * 0.9
      window.scrollTo({top: rect.bottom + currentScrolledHeight - position, behavior: "smooth"});
    },
    Usebtn: function(){
    },
    Cancellbtn: function(){

    },
    mogiri: function () {

    }
  },
  async created() {

    this.getUrlParam()
    this.init = this.storageGet('*')
    //20201204 NEW_DEV-733 A36-No.04 cyber 李 start
    this.selected_shop = this.init.selected_shop
    //20201204 NEW_DEV-733 A36-No.04 cyber 李 end

    try{
      // デジタルチケット詳細API
      var params = {
        'ticket_id': this.$route.query.ticket_id,
        'history_id': this.$route.query.history_id,
        'win_flg': 0
      };
      if (this.$route.query.win) {
        params.win_flg = 1;
      }
      var digital_ticket_params = { params:params }
      var digital_ticket = await this.apiCall('/digitalticket/detail', digital_ticket_params);
      console.log('digital_ticket_', digital_ticket)
      if (!digital_ticket) return false;
      this.$set(this, 'item', digital_ticket);

      switch (digital_ticket.ticket_type) {
        case 1:
          break;
        case 3:
          break;
        case 2:
          var count = digital_ticket.userPaidTicketHistory.number_use
          var max = digital_ticket.number_use
          var set = 0
          var styleSet = ''
          var lastflg = 0
          for (let i = max; i > count; i--) {
            var ret = ( '00' + i ).slice( -2 );
            var scale = 1 - (0.05 * set)

            if(set == 0){
              styleSet = 'position: relative; z-index:' + i + ';'
            }
            else if(set < 5){
              styleSet = 'transform: scale(' + scale + ',' + scale + '); z-index:' + i + '; top:' + (set +(10 * set)) + 'px;'
            }else{
              styleSet = 'display: none;'
            }

            if(i == count + 1){
              lastflg = 1
            }

            this.cards.push({
              num: ret,
              style: styleSet,
              lastflg: lastflg
            })
            set++;
          }
          break;
        default:
          return false;
      }

      if (this.$route.query.win) {
        this.callDialog('エントリーに当選しました', '', 1);
      }
    } catch(e) {
        this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
    }
  },
};
</script>

<style scoped>
.openbtn {
  border-radius: 0 0 15px 15px;
  width: 60%;
  margin-top: 30px;
}
.openbtn.close {
  margin-top: 0px;
}
.lh {
  line-height: 26px;
}
.bordered {
  border: 1px solid #000 !important;
}

.titlebar {
  background-color: #E4E4E4;
  padding: 5px 20px;
  font-size: 14px;
  color: #333333;
  margin-bottom: 15px;
}

.tickettitle {
  font-size: 20px;
}

.checkboxstyle {
  font-size: 11px !important;
}
.footinfo {
  background-color: #595757;
  color: #fff;
}

.ticket_detail {
  width: 336px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 15px 15px;
  position: relative;
  z-index: 2;
}

.use {
  border-radius: 15px 15px 0 0;
  width: 336px;/*90%;*/
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
.number_ticket_wrap {
  width: 336px;
  margin: 0 auto;
  position: relative;
}
.ticket_block {
  background-image: url("~@/assets/digital_ticket/number_ticket_bg@2x.png");
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  height: 120px;
  align-items: stretch;
  width: 100%;
  margin: 0 auto;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.16));
  position: absolute;
  z-index: 10;
  top: 0;
  transition:all 0.5s ease;
}
.ticket_block.opened {
  position: relative !important;
  transform: scale(1,1) !important;
  display: flex !important;
  top: 0 !important;
  z-index: 10 !important;
  transition:all 0.5s ease;
}
.ticket_number {
  width: 40px;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #595757;
}
.ticket_content {
  width: 296px;
  height: 120px;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #595757;
}
.string_ticekt {
  font-size: 10px;
  font-family: 'Oswald', sans-serif !important;
  font-weight: bold;
  margin: 0;
  line-height: 1;
}
.ticketnum {
  font-size: 30px;
  font-family: 'Oswald', sans-serif !important;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 0;
  line-height: 1;
}
.ticketcard_title {
  font-size: 13px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.5;
}
.bignum {
  font-size: 40px;
  font-family: 'Oswald', sans-serif !important;
}
.img_wrap {
  position: relative;
}
</style>
<style>
.ticketWrap {
  position: relative;
}
.ticketWrap .v-overlay__scrim {
  width: 336px;
  margin: auto;
  height: 100%;
  position: relative;
  border-radius: 15px;
}
.ticketWrap .v-application--wrap {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.ticketWrap .v-overlay__content {
  position: absolute;
}
.imgtype0 {
  color: #fff;
}
</style>
