<template>
  <v-content class="digital_ticket" v-if="item != null">
    <SelectedShop v-if="init.branch_flg == 1" :selected_shop_name="selected_shop" :router_param="{ name: 'digital_ticket_shops', query: { feature_id: 'digital_ticket'}}" ></SelectedShop>

    <div class="balwrap" v-show="item.limit_type">
      <div :class="item.limit_type == 1 ? 'balloon1' : 'balloon1 sub'">
        <p>{{item.limit_type_text}} {{item.limit_number}}名様限定</p>
      </div>
    </div>

    <TicketDesign :item="item" v-if="item.img_type != 0"></TicketDesign>

    <div v-else class="mb-5">
      <v-img v-if="item.image_path" :src="item.image_path" lazy-src="@/assets/noimg@2x.png" class="noleftrounded" width="100%">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
      </v-img>
      <v-img v-else src="@/assets/noimg@2x.png" lazy-src="@/assets/noimg@2x.png" class="noleftrounded"  width="100%">
      </v-img>
    </div>

    <div class="ticket_detail px-4 mb-5">
      <p class="mb-0 mt-0 body-2" v-html="item.description"></p>
    </div>

    <h2 class="titlebar">チケット詳細</h2>

    <div class="detailinner px-4 mb-12">
      <v-chip outlined x-small class="inline-block my-0 px-2" color="#9D9D9D" text-color="#9D9D9D">{{item.ticket_type_text}}タイプ</v-chip>
      <h3 class="mb-0 mt-0 tickettitle">{{item.title}}</h3>
      <v-divider class="my-3 border_maincolor"></v-divider>

      <v-row align="center" v-if="item.comment_for_discount != 0">
        <v-col cols="3" class="fontsize11 pb-0" style="color: #9D9D9D;">
          割引価格
        </v-col>
        <v-col cols="9" class="fontsize11 pb-0">
          <span v-if="item.comment_for_discount != 0" class="fontsize13">{{item.comment_for_discount}}<br></span>
          <span class="pricedetail">{{item.price}}</span>円(税込)<br>
          通常価格 {{item.list_price}}円(税込)
        </v-col>
      </v-row>
      <v-row align="center" v-if="item.comment_for_discount == 0">
        <v-col cols="3" class="fontsize11" style="color: #9D9D9D;">
          販売価格
        </v-col>
        <v-col cols="9" class="fontsize13 py-0">
           {{item.list_price}}円(税込)
        </v-col>
      </v-row>
      <v-row align="center" v-show="item.public_start_date || item.public_end_date">
        <v-col cols="3" class="fontsize11" style="color: #9D9D9D;">
          販売期間
        </v-col>
        <v-col cols="9" class="fontsize13">
          {{item.public_start_date}}〜{{item.public_end_date}}
        </v-col>
      </v-row>
      <v-row align="center" v-show="item.pay_timing">
        <v-col cols="3" class="fontsize11" style="color: #9D9D9D;">
          料金請求間隔
        </v-col>
        <v-col cols="9" class="fontsize13">
          {{item.pay_timing}}
        </v-col>
      </v-row>
      <v-row align="center" v-show="item.limit_type">
        <v-col cols="3" class="fontsize11" style="color: #9D9D9D;">
          {{item.limit_type_text}}
        </v-col>
        <v-col cols="9" class="fontsize13">
          {{item.limit_number}}名様限定
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="3" class="fontsize11" style="color: #9D9D9D;">
          利用回数
        </v-col>
        <v-col cols="9" class="fontsize13" v-if="item.limit_use_count">
          {{item.limit_use_count}}回
        </v-col>
        <v-col cols="9" class="fontsize13" v-else>
          定期券
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="3" class="fontsize11" style="color: #9D9D9D;">
          有効期限
        </v-col>
        <v-col cols="9" class="fontsize13">
          {{item.expiration_text}}
        </v-col>
      </v-row>
      <v-row align="center" v-show="item.attention_text">
        <v-col cols="3" class="fontsize11" style="color: #9D9D9D;">
          注意事項
        </v-col>
        <v-col cols="9" class="fontsize13">
          <!-- 20201210 NEW_DEV-733 A36-No.65 cyber 李 start -->
          <p v-html="item.attention_text"></p>
          <!-- 20201210 NEW_DEV-733 A36-No.65 cyber 李 end -->
        </v-col>
      </v-row>

      <v-row align="center" v-show="item.limit_type == 2">
        <v-col cols="3" class="fontsize11" style="color: #9D9D9D;">
          抽選チケット注意事項
        </v-col>
        <v-col cols="9" class="fontsize13">
          ※当選された場合のみ、エントリー時にご登録いただきましたクレジットカードよりご購入金額が決済されます。落選された場合には決済されません。<br>
          ※決済後および落選時にクレジットカードの情報は削除されます。<br>
          ※当選者は厳選な抽選により決定させていただきます。<br>
          ※当選されたお客様へのみプッシュ通知・メールにて確定のご連絡差し上げます。<br>
          ※お客様の端末や設定によっては、当選のプッシュ通知が届かない可能性があります。
          抽選日以降にアプリ内「購入済みチケット」タブにて、必ずご確認ください。(当選された場合当該チケットが表示されます）<br>
          ※エントリーの取り消し、当選後の取り消しは承ることができません。ご了承ください。
        </v-col>
      </v-row>

      <v-divider class="mt-3 mb-8 border_maincolor"></v-divider>

      <div v-if="init.os == 1 && item.apple_pay_flg == 1">
        <p class="body-2">決済方法選択</p>
        <v-card class="px-3 pt-1 mb-8">
        <v-radio-group v-model="paymode" :mandatory="true" class="body-1 pa-0">
          <!-- 20201208 NEW_DEV-733 A36-No.24 cyber 李 start -->
          <v-radio label="クレジット決済" value="1" :color="maincolor" class="pa-0" @click="kiyaku = false"></v-radio>
          <!-- 20201208 NEW_DEV-733 A36-No.24 cyber 李 end -->
          <v-divider class="mt-1 mb-3 border_maincolor"></v-divider>
          <v-radio label="Apple Pay" value="2" :color="maincolor" class="pa-0"></v-radio>
        </v-radio-group>
        </v-card>
      </div>

      <div class="pa-3 text-center mb-5">
        <v-btn rounded depressed class="maincolor-bg default_button w90" v-if="paymode == 1" @click="routerBindTo({ name: 'digital_pay', query: { ticket_id: item.id }})">決済情報を入力する</v-btn>

        <p id="apple-pay-private-policy" class="kakunin-kiyaku" v-if="(isApplePay == 1 || isApplePay == 2) && paymode == 2">
          <v-checkbox v-model="kiyaku" class="fontsize11 ma-0 pa-0">
            <template v-slot:label>
              <div class="fontsize11">
                <!-- 20201208 NEW_DEV-733 A36-No.21 cyber 李 start -->
                <span style="color: #1976D3; text-decoration: underline;" @click="ruleDialog = true">利用規約およびプライバシーポリシー</span>に同意の上、購入する
                <!-- 20201208 NEW_DEV-733 A36-No.21 cyber 李 end -->
              </div>
            </template>
          </v-checkbox>
        </p>

        <v-btn id="apple-pay" v-bind:disabled="!kiyaku" rounded depressed color="black" v-if="(isApplePay == 1 || isApplePay == 2) && paymode == 2" class="apple-pay-button-with-text default_button w90" @click.stop="callApplePay();"><img src="@/assets/digital_ticket/applepay.svg"></v-btn>

        <v-btn rounded depressed class="graybtn default_button w60 mt-5" @click="$router.go(-1)">キャンセルする</v-btn>
      </div>

        <p class="caption" @click="qaDialog = true">
          <v-icon small color="black">fas fa-info-circle</v-icon> よくあるご質問 Q &amp; A
        </p>

    </div>

    <v-footer fixed class="footinfo" v-if="item.sales_promotion_text">
      <v-col class="text-center pa-0 caption" cols="12">
        <v-icon x-small color="white">far fa-clock</v-icon> {{item.sales_promotion_text}}
      </v-col>
    </v-footer>

    <!-- 20201208 NEW_DEV-733 A36-No.21 cyber 李 start -->
    <!-- 利用規約およびプライバシーポリシーダイアログ -->
    <v-dialog v-model="ruleDialog" height="522">
      <v-card class="pa-4">
        <Rule :dialog_flg="true"></Rule>
        <div class="text-center">
          <v-btn rounded depressed class="caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click="ruleDialog = false">閉じる</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- 20201208 NEW_DEV-733 A36-No.21 cyber 李 end -->

    <!-- よくあるご質問ダイアログ -->
    <v-dialog v-model="qaDialog" height="522">
      <v-card class="pa-4">

        <h4 class="caption mb-5">よくあるご質問 Q &amp; A</h4>
        <h5 class="fontsize18">チケット購入について</h5>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mb-10">
          <v-col cols="2" class="pb-0">
                <v-avatar color="black" size="24"><span class="body-2 white--text">Q</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0 pb-0 font-weight-bold">
                チケットの購入方法は選べますか？
          </v-col>

          <v-col cols="2" class="">
                <v-avatar color="white" size="24" class="bordered"><span class="body-2">A</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0">
                チケットの購入は当アプリからのみ購入が可能です。
          </v-col>

          <v-col cols="2" class="pb-0">
                <v-avatar color="black" size="24"><span class="body-2 white--text">Q</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0 pb-0 font-weight-bold">
                一度登録したクレジットカード情報は次回購入時にも引き継がれますか？
          </v-col>

          <v-col cols="2" class="">
                <v-avatar color="white" size="24" class="bordered"><span class="body-2">A</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0">
                引き継がれません。
          </v-col>

          <v-col cols="2" class="pb-0">
                <v-avatar color="black" size="24"><span class="body-2 white--text">Q</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0 pb-0 font-weight-bold">
                購入時に登録したクレジットカード情報を変更することは可能ですか？
          </v-col>

          <v-col cols="2" class="">
                <v-avatar color="white" size="24" class="bordered"><span class="body-2">A</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0">
                次回購入時にクレジットカード情報を打ち込む際に変更してください。一度カード情報を登録して購入が完了したチケットはカード情報を変更することはできません。
          </v-col>

          <v-col cols="2" class="pb-0">
                <v-avatar color="black" size="24"><span class="body-2 white--text">Q</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0 pb-0 font-weight-bold">
                購入したチケットの代金が引き落とされるのはいつですか？
          </v-col>

          <v-col cols="2" class="">
                <v-avatar color="white" size="24" class="bordered"><span class="body-2">A</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0">
                登録したクレジットカードのお支払日と同じになります。詳しくは各カード会社にお問い合わせください。
          </v-col>

          <v-col cols="2" class="pb-0">
                <v-avatar color="black" size="24"><span class="body-2 white--text">Q</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0 pb-0 font-weight-bold">
                購入が完了しているか確認できますか？
          </v-col>

          <v-col cols="2" class="">
                <v-avatar color="white" size="24" class="bordered"><span class="body-2">A</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0">
                購入が完了したチケットはチケット一覧画面内の「購入済みチケット」内に表示されます。チケットをご利用になる際にもこちらからご利用いただけます。
          </v-col>
        </v-row>


        <h5 class="fontsize18">キャンセル・返金について</h5>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mb-10">
          <v-col cols="2" class="pb-0">
                <v-avatar color="black" size="24"><span class="body-2 white--text">Q</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0 pb-0 font-weight-bold">
                購入したチケットをキャンセル（解約）することはできますか？
          </v-col>

          <v-col cols="2" class="">
                <v-avatar color="white" size="24" class="bordered"><span class="body-2">A</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0">
                ●チケットタイプが一回券・回数券タイプのチケットは一度購入するとキャンセルができません。ご購入の前にチケット詳細をよくご確認の上、ご購入ください。<br>
<br>
●チケットタイプが定期券（自動更新型定期券）のチケットは「解約する」ボタンより解約が行えます。解約時にチケットの有効期限が残っている場合は有効期限内まで引き続きご利用が可能です。有効期限を過ぎますと、解約が確定となり次回の引き落としは実行されません。<br>
<br>
●チケットタイプが定期券（期間限定型定期券）はキャンセル（解約）ができません。
          </v-col>

          <v-col cols="2" class="pb-0">
                <v-avatar color="black" size="24"><span class="body-2 white--text">Q</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0 pb-0 font-weight-bold">
                キャンセルしたチケットの返金は対応していますか？
          </v-col>

          <v-col cols="2" class="">
                <v-avatar color="white" size="24" class="bordered"><span class="body-2">A</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0">
                一度キャンセル（解約）をしたチケットの返金は対応していません。チケット詳細をよくご確認の上、ご購入ください。
          </v-col>

          <v-col cols="2" class="pb-0">
                <v-avatar color="black" size="24"><span class="body-2 white--text">Q</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0 pb-0 font-weight-bold">
                チケットを誤って購入してしまった場合、チケットの返金などは対応していますか？
          </v-col>

          <v-col cols="2" class="">
                <v-avatar color="white" size="24" class="bordered"><span class="body-2">A</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0">
                いかなる場合でもご購入済みチケットの返金には対応していません。
          </v-col>
        </v-row>


        <h5 class="fontsize18">その他</h5>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mb-10">
          <v-col cols="2" class="pb-0">
                <v-avatar color="black" size="24"><span class="body-2 white--text">Q</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0 pb-0 font-weight-bold">
                チケット有効期限の残りがある状態で誤ってアプリをアンインストールしてしまいました。<br>
                再度インストールし直せば購入したチケットは引き続き使えますか？
          </v-col>

          <v-col cols="2" class="">
                <v-avatar color="white" size="24" class="bordered"><span class="body-2">A</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0">
                アンインストールする前に、設定画面内／ユーザー情報の会員ID・PINコードをお控えください。再度インストール時に会員ID・PINコードを再度ご入力いただくことで引き続きご利用が可能です。<br>
                会員ID・PINコードを忘れてしまった場合はユーザー情報が引き継がれず、チケットの継続利用もできなくなりますのでご注意ください。
          </v-col>

          <v-col cols="2" class="pb-0">
                <v-avatar color="black" size="24"><span class="body-2 white--text">Q</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0 pb-0 font-weight-bold">
                クレジットカードの情報および決済情報はどのように管理されていますか？
          </v-col>

          <v-col cols="2" class="">
                <v-avatar color="white" size="24" class="bordered"><span class="body-2">A</span></v-avatar>
          </v-col>
          <v-col cols="10" class="body-2 lh px-0">
                当社では安全性を高めるため、カード情報や決済会社間でやりとりする決済情報を保有することはしておりません。決済情報は、クレジットカード会社、もしくは当社決済会社として利用するstripe（<a href="https://stripe.com/jp">https://stripe.com/jp</a>）との間でセキュリティ基準に遵守した形式での情報のやり取りとなりとなります。
          </v-col>
        </v-row>

        <div class="text-center">
          <v-btn rounded depressed class="caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click="qaDialog = false">閉じる</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

  </v-content>

</template>

<script>
import SelectedShop from '@/components/selected_shop.vue'
import TicketDesign from '@/views/digital_ticket/ticket_design.vue'
import Rule from "@/views/info/rule";
export default {
  components: {
    SelectedShop,
    TicketDesign,
    Rule,
  },
  data() {
    return {
      user_info: null,
      init: [],
      page: 1,
      result: null,
      data: null,
      model: null,
      maincolor: this.storageGet('color_code'),
      selected_shop: "全て",
      item: null,
      stripe_customer_id: null,
      stripe_customer_info: null,
      paymode: 1,
      privacycheck: false,
      qaDialog: false,
      ruleDialog: false,
      isApplePay: 0,
      stripe: "",
      card: null,
      loading: false,
      payment: null,
      name: null,
      phone: null,
      email: null,
      kiyaku: false,
    };
  },

  async mounted () {

    // native側から叩けるようグローバル化したい関数を、下記に登録
    window.applePaySetupButton = () => { this.applePaySetupButton(); }
    window.applePayBuyButton = () => { this.applePayBuyButton(); }
    window.completeApplePay = (result) => { this.completeApplePay(result); }

  },


  methods: {

      applePayClose(){
      var ticketUrl = '' // sample  todo
          window.location.href = ticketUrl;
      },

      /**
       * Apple Pay Button 生成(APPLE PAY 決済確定用UI表示 ボタン)
       * @return boolean
       */
      applePayBuyButton(){
        this.isApplePay = 1
        return true;
      },

      /**
       * Apple Pay Button 生成(クレジットカードセットアップUI表示ボタン)
       * @return boolean
       */
      applePaySetupButton(){
        this.isApplePay = 2
        return true;
      },

      /**
       * Apple Pay Button タップ
       * @return boolean
       */
      async callApplePay() {

          if (this.kiyaku == false) {
              alert("利用規約およびプライバシーポリシーに同意の上、ご購入ください");
              return
          }

          // 決済トークン更新
          let settlementTokenUpdate = await this.settlementTokenUpdate()
          if (settlementTokenUpdate) {
            this.init = this.storageGet('*')
          } else {
            this.loading = false
            return false
          }

          var applePayParams = {};
          applePayParams['_vue_param'] = true;
          applePayParams['apple_pay'] = this.isApplePay;
          applePayParams['name'] = this.user_info.name;
          applePayParams['tel'] = this.user_info.tel;
          applePayParams['mail'] = this.user_info.mail;
          applePayParams['t_paid_ticket_id'] = this.item.id;
          applePayParams['currency'] = 'jpy';
          applePayParams['description'] = this.item.title;
          applePayParams['amount'] = this.item.price;

          this.nativePost(applePayParams);

      },

      /**
       * Apple Pay Button タップ
       * @return boolean
       */
      completeApplePay (result) {

        alert(JSON.stringify(result));

        if (!result || !(result.status == 100 || result.status == 200) ) {
          this.callDialog('決済処理エラー', '決済処理の処理でエラーとなりました。', 3);
        } else {
          //20210128 NEW_DEV-768 cyber 李 start
          this.callDialog('支払い完了', '決済が完了しました', 5, this.item.id, result.user_paid_ticket_history_id);
          //20210128 NEW_DEV-768 cyber 李 end
        }

        return true;
      },


  },

  async created() {

    this.getUrlParam()
    this.init = this.storageGet('*')
    //20201204 NEW_DEV-733 A36-No.04 cyber 李 start
    this.selected_shop = this.init.selected_shop
    //20201204 NEW_DEV-733 A36-No.04 cyber 李 end

    try{
      // デジタルチケット詳細API
      var digital_ticket_params = { params:{
        'ticket_id': this.init.ticket_id,
      }}
      //20201228 NEW_DEV-733 C46 cyber 李 start
      if(this.$route.query && this.$route.query.preview){
        digital_ticket_params = { params:{
          'ticket_id': this.$route.query.id,
          'preview': this.$route.query.preview,
        }}
      }

      var digital_ticket = await this.apiCall('/digitalticket/detail', digital_ticket_params);
      console.log('digital_ticket', digital_ticket)
      if (!digital_ticket) return false;
      this.$set(this, 'item', digital_ticket);

      if(!this.$route.query.preview) {
        // ユーザ情報取得
        var user_info_params = {params: {}}
        var user_info = await this.apiCall('/user/info', user_info_params);
        if (!user_info) return false;
        console.log("user_info", user_info);
        this.$set(this, 'user_info', user_info);
      }
      //20201228 NEW_DEV-733 C46 cyber 李 end
      if (this.item.apple_pay_flg == 1 && this.storageGet('os') == 1) {
          this.nativePost({_vue_param: true, apple_pay_init: true})
      }
    } catch(e) {
        this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
    }
  },



};
</script>

<style scoped>
.lh {
  line-height: 26px;
}
.bordered {
  border: 1px solid #000 !important;
}
.balloon1 {
  position: relative;
  display: block;
  margin: 1.5em auto;
  padding: 2px 20px;
  min-width: 120px;
  max-width: 100%;
  width: 60%;
  font-size: 14px;
  color: #fff;
  background: #35D4CA;
  border-radius: 20px;
}
.balloon1.sub {
  background: #9DD435;
}

.balloon1:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border: 10px solid transparent;
  border-top: 10px solid #35D4CA;
}

.balloon1.sub:before {
  border-top: 10px solid #9DD435;
}

.balloon1 p {
  margin: 0;
  padding: 0;
  text-align: center;
}



.titlebar {
  background-color: #E4E4E4;
  padding: 5px 20px;
  font-size: 14px;
  color: #333333;
  margin-bottom: 15px;
}

.tickettitle {
  font-size: 20px;
}

.checkboxstyle {
  font-size: 11px !important;
}
.footinfo {
  background-color: #595757;
  color: #fff;
}

/* Apple Pay UI FROM */
.apple-pay-button-with-text {
  display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 60px;
    box-sizing: border-box;
    width: 70%;
    min-height: 32px;
    max-height: 64px;
    /*background-image: -webkit-named-image(apple-pay-logo-white);*/
    text-decoration: none;
    font-size: 1.5rem;
    line-height: 1.4;
    outline: 0;
    border: 0px;
    cursor: pointer;
    box-shadow: 0 0 6px rgba(0,0,0,0.3);
}

.apple-pay-button-with-buy {
  -apple-pay-button-type: buy;
}

.apple-pay-button-with-setup {
  -apple-pay-button-type: set-up;
}

.apple-pay-button-with-text > * {
    /*display: none;*/
}

.hidden {
  display:none;
}
.pricedetail {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Roboto';
}

/* Apple Pay UI TO */

</style>
