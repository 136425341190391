<template>
		<v-content class="px-3 mb-10" v-if="init && shop">
        <div class="modal-wrapper">
          <div class="detailpage">
              <p class="body-2 my-5 text-center">今後のサービス向上のため<br>アンケートにご協力ください</p>
              <h2 class="text-left body-2 font-weight-bold">解約の理由を教えてください。</h2>

              <div class="checkboxs">
                <v-radio-group v-model="reason">
                  <v-radio label="他のサービスを利用する" value="1" :color="maincolor"></v-radio>
                  <v-radio label="通えなくなった" value="2" :color="maincolor"></v-radio>
                  <v-radio label="店舗での対応に問題があった" value="3" :color="maincolor"></v-radio>
                  <v-radio label="サービスを使わなくなった" value="4" :color="maincolor"></v-radio>
                  <v-radio label="金額が高い" value="5" :color="maincolor"></v-radio>
                </v-radio-group>
              
                <v-textarea v-model="other" outlined label="その他"></v-textarea>
              </div>

              <v-divider class="mb-8"></v-divider>

              <div class="fontsize11">
                  <dl>
                      <dt>注意事項</dt>
                      <dd>※途中解約の場合、日割りでの返金は対応しておりません。</dd>
                      <dd>※チケット有効期限内での途中解約でも、チケット有効期限までは引き続き本チケットのご利用が可能です。</dd>
                      <dd>※有効期限が切れるまでは購入済みチケット一覧より本チケットを確認することが可能です。</dd>
                      <dd>※有効期限を過ぎたチケットはチケット購入一覧画面より削除されます。</dd>
                      <dd>※解約はチケット有効期限が切れると同時に確定となります。</dd>
                      <dd>※解約実行後の解約取り消しには対応しておりません。同チケットを引き続きご利用になる場合は、改めてチケットをご購入していただく必要があります。</dd>
                  </dl>
              </div>

              <v-divider class="my-8"></v-divider>

              <div class="fontsize11 mb-10">
                  <dl>
                      <dt class="font-weight-bold">お問い合わせ先</dt>
                      <dd>
                          <p class="name my-1">{{init.shop_name}}</p>
                          <p class="tel my-1"><a :href="'tel:'+shop.tel.value">TEL：<span>{{shop.tel.value}}</span></a></p>
                          <p>{{shop.opening_hours.name}}：{{shop.opening_hours.value}}</p>
                      </dd>
                  </dl>
              </div>

              <div class="text-center">
                <v-btn rounded depressed class="maincolor-bg btn_small default_button w60 py-5" @click="cancel">解約する</v-btn>
              </div>
          </div>
        </div>

		<!-- 解約完了ダイアログ -->
    <v-dialog v-model="Dialog" content-class="dialog">
      <v-card class="text-center py-8">
          <h1 class="maincolor subtitle-1 font-weight-bold mb-3">サービスをご利用いただき<br>ありがとうございました</h1>
        <p class="subtitle-2 text-center pa-0 my-3" style="width:100%;">本チケットは{{init.expiration_text}}まで<br>引き続きご利用可能です。</p>
        <div class="text-center">
          <v-btn rounded class="maincolor-bg default_button btn_small" @click.stop="closebtn">閉じる</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

		</v-content>
</template>

<script>
export default {
	data() {
    return {
      init: [],
      shop_name: this.storageGet('shop_name'),
      maincolor: this.storageGet('color_code'),
      reason: null,
      other: null,
      shop: null,
      loading: false,
      Dialog: false,
    }
  },
  async created() {
    this.getUrlParam()
    this.init = this.storageGet('*')
    var req = { params:{
      'shop_id': this.init.sid,
    }}

    try {
      var res = await this.apiCall('/user/shop/detail', req)

      this.$set(this, 'shop', res.shop)
      if (!res) return false;
    } catch(e) {
      this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
    }

  },
  methods: {
    async cancel(){

      if (this.reason == null) {
        alert('解約の理由を選択してください。')
        return false 
      }

      this.loading = true

      try {

        // 定期解約処理実行パラメータ
        var cancelParams = {}
        cancelParams['stripe_id'] = this.init.stripe_id
        cancelParams['shop_id'] = this.init.shop_id
        cancelParams['cooperate_type'] = 0
        //console.log('cancelParams', cancelParams)

        // STRIPEに定期解約処理依頼
        var stripe_cancel_res = await this.apiCall('cancel-subscription', cancelParams, 'post', 3);

        //console.log('stripe_cancel_res', stripe_cancel_res)
        if (!stripe_cancel_res || stripe_cancel_res.status_cd != 200) {
            this.loading = false
            this.callDialog('定期チケット解約処理エラー', '定期チケット解約処理でエラーとなりました。', 3);
            return false;
        }

        delete cancelParams['cooperate_type']
        cancelParams['t_paid_ticket_id'] = this.init.ticket_id
        cancelParams['reason'] = this.reason
        cancelParams['other'] = this.other

        // UPLINKに定期解約処理履歴登録
        var cancel_history_res = await this.apiCall('stripe/cancel-subscription', cancelParams, 'post', 0);

        console.log('cancel_history_res', cancel_history_res)

        if (!cancel_history_res || cancel_history_res.status != 100) {
            this.loading = false
            this.callDialog('定期チケット解約処理エラー', '定期チケット解約処理でエラーとなりました。', 3);
            return false;
        }

        this.Dialog = true

      } catch(e) {
        this.loading = false
        this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
      }
    },
    closebtn() {
      this.Dialog = false
      this.routerBindTo({ name: 'digital_buy', query: { ticket_id: this.init.ticket_id }})
    }
  }
}
</script>

<style scoped>
.checkboxs >>> label {
  font-size: 13px;
}
</style>
