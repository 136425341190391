<template>
  <v-content class="digital_ticket ticket_list">
		<SelectedShop v-if="init.branch_flg == 1" :selected_shop_name="selected_shop" :router_param="{ name: 'digital_ticket_shops', query: { feature_id: 'digital_ticket'}}" ></SelectedShop>

    <v-tabs
      v-model="model"
      class="px-4"
      grow
      background-color="transparent"
      v-bind:color="maincolor"
    >
      <v-tab href="#sale" @click="SaveTab('sale')">販売中チケット</v-tab>
      <v-tab href="#buy" @click="SaveTab('buy')">購入済みチケット</v-tab>
    </v-tabs>

    <v-tabs-items
      class="px-4 pt-5"
      v-model="model"
      style="background-color: #f9f9f9;"
    >
      <v-tab-item value="sale">
        <!-- 20201207 NEW_DEV-733 A35-No.43,44 cyber 李 start -->
        <v-card class="overflow-hidden mb-7" min-height="168" v-for="(item, i) in data" :key="i">
          <v-row class="" align="stretch" no-gutters @click="routerBindTo({ name: 'digital_detail', query: { ticket_id: item.id }})">
            <!-- 20201207 NEW_DEV-733 A35-No.43,44 cyber 李 end -->
            <v-col cols="3" class="text-center ma-0 pa-0" style="min-height: 168px;">
              <div class="py-2" style="background-color: #35d4ca;" v-if="item.limit_type == 1">
                <p class="overline mb-0 white--text">- {{item.limit_type_text}} -</p>
                <p class="title white--text mb-0" style="line-height: 1.2rem;">
                  {{item.limit_number}}<span class="overline">名</span>
                </p>
              </div>
              <div class="py-2" style="background-color: #9DD435;" v-if="item.limit_type == 2">
                <p class="overline mb-0 white--text">- {{item.limit_type_text}} -</p>
                <p class="title white--text mb-0" style="line-height: 1.2rem;">
                  {{item.limit_number}}<span class="overline">名</span>
                </p>
              </div>
              <div class="py-2">
                <div class="pt-4">
                  <img v-if="item.ticket_type_text == '1回券'" src="@/assets/digital_ticket/digitalticket_ticket.svg" />
                  <img v-else-if="item.ticket_type_text == '回数券'" src="@/assets/digital_ticket/digitalticket_number.svg" />
                  <img v-else-if="item.ticket_type_text == '定期券'" src="@/assets/digital_ticket/digitalticket_teiki.svg" />
                </div>
                <v-chip outlined x-small class="ma-0 px-2" color="#9d9d9d">
                  {{item.ticket_type_text}}
                </v-chip>
              </div>
            </v-col>
            <v-col
              class="pa-3"
              cols="9"
              v-bind:style="{
                'background-size': 'cover',
                'background-image': 'url(' + item.image_path + ')',
                'position': 'relative',
                'min-height': '168px',
                'align-items': 'center',
                'display': 'flex',
              }"
            >
              <v-overlay :absolute="true" opacity="0.65" v-if="item.img_type == 0" z-index="2">
              </v-overlay>
              <div :class="item.img_type == 0 ? 'imgtype0' : ''" style="z-index: 3; position:relative;">
              <p class="subtitle-2 font-weight-bold mb-0">
                {{item.title}}
              </p>
              <div class="headline d-inline mr-2">
                {{item.price}}<span class="subtitle-2">円</span>
              </div>
              <v-chip
                outlined
                x-small
                class="inline-block ma-0 px-2"
                :color="item.img_type == 0 ? '#fff' : '#000'"
              >
                税込
              </v-chip>
              <p class="fontsize9 pt-2 mb-2" style="line-height: 0.8rem;">
                {{item.terms_of_use_text}}
              </p>
              <v-alert
                dense
                dark
                class="text-center fontsize9 pa-0 mb-0"
                color="#E55497"
                >{{item.sales_promotion_text}}</v-alert
              >
              </div>
            </v-col>
          </v-row>
          <!-- 20201207 NEW_DEV-733 A35-No.43,44 cyber 李 start -->
          <v-overlay absolute opacity="0.7" v-if="item.limit_type == 1 && item.limit_number - item.sold_number <= 0">
            <div class="text-center">
              <p class="flgtext">チケットは完売しました</p>
            </div>
          </v-overlay>
          <!-- 20201207 NEW_DEV-733 A35-No.43,44 cyber 李 end -->
        </v-card>
      </v-tab-item>

      <v-tab-item value="buy">
        <Bought :init="init" v-if="isBought"></Bought>
      </v-tab-item>
    </v-tabs-items>

    <!-- InfiniteLoading -->
    <infinite-loading ref="infiniteLoading"  @infinite="infiniteHandler" spinner="spiral">
      <div slot="spinner"><v-progress-circular :size="30" :width="2" class="maincolor" indeterminate></v-progress-circular></div>
      <div slot="no-more"></div>
      <!-- 20201203 NEW_DEV-733 A35-No.17 cyber 李 start -->
      <div v-if="this.$root.digiticketTab == 'sale'" slot="no-results">現在販売中のチケットはありません</div>
      <div v-else slot="no-results"></div>
      <!-- 20201203 NEW_DEV-733 A35-No.17 cyber 李 end -->
    </infinite-loading>

  </v-content>
</template>

<script>
import SelectedShop from '@/components/selected_shop.vue'
import InfiniteLoading from 'vue-infinite-loading'
import Bought from "@/views/digital_ticket/bought.vue";
export default {
  components: {
    SelectedShop,
    InfiniteLoading,
    Bought
  },
  data() {
    return {
      init: [],
      page: 1,
      result: null,
      data: null,
      model: this.$root.digiticketTab,
      maincolor: this.storageGet('color_code'),
      isBought: 0
    };
  },
  methods: {
      onClickBtn(link) {
          this.$router.push(link)
      },
      async infiniteHandler() {
          var shop_id = this.getUrlGetParam('shop_id');
          var selected_shop = this.getUrlGetParam('selected_shop');

          if (selected_shop) {
              this.selected_shop = selected_shop
          } else {
              this.selected_shop = '全て'
          }
          //20201204 NEW_DEV-733 A36-No.04 cyber 李 start
          this.storageSave(JSON.stringify({'selected_shop': this.selected_shop}))
          //20201204 NEW_DEV-733 A36-No.04 cyber 李 end

          var params = {
              'page': this.page,
              'is_bought': 0
          }

          if (shop_id) {
            params['shop_id'] = shop_id
          }

          var req = { params: params }

          var res = await this.apiCall('/digitalticket/list', req)

          if (res.data && res.data.length > 0) {
            if (this.page == 1) {
                this.result = res
                this.data = res.data
            } else {
                res.data.forEach(function (tmp) {
                    this.data.push(tmp);
                }.bind(this));
            }
            this.page = this.page + 1
            this.$refs.infiniteLoading.stateChanger.loaded()
          } else {
            this.$refs.infiniteLoading.stateChanger.complete()
          }
      },
      SaveTab(tabname) {
        this.isBought = tabname == 'sale' ? 0 : 1
        this.$root.digiticketTab = tabname
      }
  },
  created() {
    this.getUrlParam()
    this.init = this.storageGet('*')
    this.selected_shop = this.init.selected_shop
    this.isBought = this.$route.query.is_bought && this.$route.query.is_bought == 1 ? 1 : 0
    this.model = !this.isBought ? 'sale' : 'buy'
  },
};
</script>

<style scoped>
.imgtype0 {
  color: #fff;
}
</style>
<style>
.ticket_list .v-overlay__scrim {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.v-application--wrap {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
</style>
