<template>
  <v-content class="digital_ticket" v-if="item != null">

    <h1 class="mt-8 text-center fontsize18 font-weight-bold">決済情報入力</h1>

    <div class="mb-5" style="width:30%; margin:0 auto;">
      <v-divider class="my-2 border_maincolor"></v-divider>
    </div>

    <div class="body-2 py-2 px-4 mb-1 font-weight-bold" style="background-color: #E4E4E4; color: #333333;">
      お客様情報 <v-chip x-small class="ml-2 maincolor-bg">必須</v-chip>
    </div>

    <ValidationObserver ref="observer">
		<div class="pa-4">
      <h3 class="fontsize13 font-weight-bold" style="color:#9D9D9D;">氏名</h3>
      <ValidationProvider v-slot="{ errors }" name="氏名" rules="required">
      <v-text-field class="pt-0 mb-4 subtitle-2" v-model="name" placeholder="氏名を入力してください" single-line :error-messages="errors" required></v-text-field>
      </ValidationProvider>

      <h3 class="fontsize13 font-weight-bold" style="color:#9D9D9D;">メールアドレス</h3>
      <ValidationProvider v-slot="{ errors }" name="メールアドレス" rules="required|email">
      <v-text-field class="pt-0 mb-4 subtitle-2" v-model="mail" placeholder="メールアドレスを入力してください" single-line type="email" :error-messages="errors" required></v-text-field>
      </ValidationProvider>

      <h3 class="fontsize13 font-weight-bold" style="color:#9D9D9D;">電話番号(ハイフンなし)</h3>
      <ValidationProvider v-slot="{ errors }" name="電話番号" rules="required">
      <v-text-field class="pt-0 mb-4 subtitle-2" v-model="tel" placeholder="電話番号を入力してください" single-line type="tel" :error-messages="errors" required></v-text-field>
      </ValidationProvider>
		</div>
    </ValidationObserver>

    <div class="body-2 py-2 px-4 mb-1 font-weight-bold" style="background-color: #E4E4E4; color: #333333;">
      クレジットカード情報 <v-chip x-small class="ml-2 maincolor-bg">必須</v-chip>
    </div>

    <div class="pa-4 mb-3">
      <p class="fontsize13 mb-2">各種クレジットカード（Visa、Master Card、Discover、American Express、Diners Club、JCB）でお支払いいただけます。</p>
      <v-row class="cardimg" no-gutters justify="start" align-content="start">
        <v-col>
          <img src="@/assets/digital_ticket/visa@2x.png" width="53">
        </v-col>
        <v-col>
          <img src="@/assets/digital_ticket/mastercard@2x.png" width="53">
        </v-col>
        <v-col>
          <img src="@/assets/digital_ticket/discover@2x.png" width="53">
        </v-col>
        <v-col>
          <img src="@/assets/digital_ticket/american-express@2x.png" width="53">
        </v-col>
        <v-col>
          <img src="@/assets/digital_ticket/dinners-club@2x.png" width="53">
        </v-col>
        <v-col>
          <img src="@/assets/digital_ticket/jcb@2x.png" width="53">
        </v-col>
      </v-row>

      <dl v-if="stripeForm" class="dl-ticket-kessai">
          <dt>カード番号</dt>
          <dd><div id="card-errors" ref="cardErrors" class="error-text" role="alert"></div></dd>
          <dd class="StripeElement-Input" id="card-number-element"></dd>
          <dt >有効期限</dt>
          <dd ><div id="cardExpiry-errors" class="error-text" role="alert"></div></dd>
          <dd  class="StripeElement-Input" id="card-expiry-element"></dd>
          <dt >セキュリティコード</dt>
          <dd ><div id="cardCvc-errors" class="error-text" role="alert"></div></dd>
          <dd  class="StripeElement-Input" id="card-cvc-element"></dd>
      </dl>

      <dl v-if="stripeInfo" class="dl-ticket-kessai">
          <dt>カード番号</dt>
          <dd><div class="error-text" role="alert"></div></dd>
          <dd v-if="stripe_customer_info != null" class="StripeElement-Input" id="card-number-display">**** **** **** {{stripe_customer_info.stripe_customer_data.sources.data[0].last4}}</dd>
          <div class="pa-3 text-center mb-5">
            <v-btn v-if="stripe_customer_info != null" rounded depressed class="maincolor-bg default_button w80 py-5" @click="stripInput">決済情報を入力する</v-btn>
          </div>
      </dl>

      <p v-if="stripeForm" class="caption mb-8" @click="securitycodeDialog = true">
        <v-icon small color="black">fas fa-info-circle</v-icon> セキュリティコードについて
      </p>

      <p class="kakunin-kiyaku">
        <v-checkbox v-model="kiyaku" class="fontsize11 ma-0 pa-0">
          <template v-slot:label>
            <div class="fontsize11">
              <!-- 20201208 NEW_DEV-733 A36-No.21 cyber 李 start -->
              <span style="color: #1976D3; text-decoration: underline;" @click="ruleDialog = true">利用規約およびプライバシーポリシー</span>に同意の上、{{payText}}する
              <!-- 20201208 NEW_DEV-733 A36-No.21 cyber 李 end -->
            </div>
          </template>
        </v-checkbox>
      </p>

      <p v-if="stripeForm" class="kakunin-kiyaku">
        <v-checkbox v-model="keepCard" :label="`決済情報を登録して保存する`" class="checkfont fontsize11 ma-0 pa-0"></v-checkbox>
      </p>

      <div class="px-4 text-center">
        <v-btn rounded class="maincolor-bg default_button2 title" @click="entryConfirm = true" v-if="item.limit_type == 2">エントリーする</v-btn>
        <v-btn rounded class="maincolor-bg default_button2 title" @click="pay" v-else>購入する</v-btn>
        <v-btn rounded depressed class="graybtn default_button w70 mt-5 mb-12" @click="$router.go(-1)">キャンセルする</v-btn>
      </div>

    </div>

    <!-- 20201208 NEW_DEV-733 A36-No.21 cyber 李 start -->
    <!-- 利用規約およびプライバシーポリシーダイアログ -->
    <v-dialog v-model="ruleDialog" height="522">
      <v-card class="pa-4">
        <Rule :dialog_flg="true"></Rule>
        <div class="text-center">
          <v-btn rounded depressed class="caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click="ruleDialog = false">閉じる</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- 20201208 NEW_DEV-733 A36-No.21 cyber 李 end -->

		<!-- セキュリティコードについてダイアログ -->
		<v-dialog v-model="securitycodeDialog">
      <v-card class="pa-4">

        <h1 class="body-1 mb-4"><v-icon small color="black">fas fa-info-circle</v-icon> セキュリティーコードについて</h1>
        <p class="inner fontsize11">セキュリティーコードとは、クレジットカードの裏面にある右端の番号３桁（American Expressは表面の４桁）の数字です。</p>
        <table style="width: 100%">
        <tbody><tr>
            <td class="card"><img src="@/assets/digital_ticket/creditcard_security1.svg" alt=""></td>
                                <td class="body-2">Visa<br>
                Master Card
            </td>
                          </tr>
        <tr>
            <td class="card"><img src="@/assets/digital_ticket/creditcard_security2.svg" alt=""></td>
            <td class="body-2">American Express</td>
        </tr>
        </tbody></table>


        <div class="text-center mt-10">
          <v-btn rounded depressed class="graybtn caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click="securitycodeDialog = false">閉じる</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- エントリー確認ダイアログ -->
    <v-dialog v-model="entryConfirm">
      <v-card class="pa-4">

        <h3 class="maincolor text-center entry_dialogue_title">エントリー時の注意事項</h3>
        <p class="entry_dialogue_text">・当選した場合、チケットはチケット一覧の「購入済みチケット」内に移動します。<br>・落選した場合、チケット一覧からチケットが自動で削除されます。<br>・当選時に自動で決済が行われます。</p>
        <div class="px-4 text-center">
          <v-btn rounded class="maincolor-bg default_button2 title" @click="pay">エントリーする</v-btn>
          <v-btn rounded depressed class="graybtn default_button w70 mt-5 mb-12" @click="entryConfirm = false">キャンセルする</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

  </v-content>

</template>

<script>
import {loadStripe} from '@stripe/stripe-js';
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import Rule from "@/views/info/rule";
setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} は必須項目です。',
})

extend('email', {
  ...email,
  message: '正しいメールアドレス形式ではありません',
})
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Rule,
  },
  data() {
    return {
      init: [],
      page: 1,
      result: null,
      data: null,
      model: null,
      maincolor: this.storageGet('color_code'),
      item: null,
      user_info: null,
      stripe_customer_id: null,
      stripe_customer_info: null,
      stripeForm: false,
      stripeInfo: false,
      paymode: 2,
      privacycheck: false,
      securitycodeDialog: false,
      ruleDialog: false,
      isApplePay: 0,
      stripe: "",
      card: null,
      loading: false,
      payment: null,
      name: null,
      tel: null,
      mail: null,
      keepCard: false,
      kiyaku: false,
      payText: '購入',
      payText2: '購入',
      entryConfirm: false
    };
  },

  async mounted () {

    // native側から叩けるようグローバル化したい関数を、下記に登録
    window.applePaySetupButton = () => { this.applePaySetupButton(); }
    window.applePayBuyButton = () => { this.applePayBuyButton(); }
    window.completeApplePay = (result) => { this.completeApplePay(result); }

  },

  methods: {

    stripInput () {
      this.stripeForm = true;
      this.stripeInfo = false;
      this.makeStripeForm(this.item)
    },
    async pay () {
        if (this.kiyaku == false) {
            alert("利用規約およびプライバシーポリシーに同意の上、ご購入ください");
            return
        }
        this.loading = true;

        // STRIPE 顧客IDがない場合、もしくは再入力した場合、Token 発行
        if (this.stripe_customer_info == null || this.stripeForm) {
            this.stripe.createToken(this.card).then(this.tokenCallback)
        } else {
            this.tokenCallback(null)
        }
    },

    async tokenCallback (result) {
        if (result != null && result.error) {
            this.loading = false
            // Inform the user if there was an error.
            var errorElement = document.getElementById('cardTotal-errors');
            var carDisplayError = document.getElementById('card-errors');
            if (carDisplayError.innerHTML != result.error.message) {
                errorElement.textContent = result.error.message;
            }
        } else {

            try {
                // ストライプ顧客情報が未登録の場合、新規登録
                // ストライプ顧客情報が登録済、かつカードトークンを比較し、変更されていたらストライプ顧客情報を更新

                // 決済API用パラメータ
                var payParams = {};
                // ストライプ顧客情報登録/更新用パラメータ
                var customerParams = {};
                // ユーザ情報更新用パラメータ
                var userParams = {};
                // UPLINK DB 購入履歴用パラメータ
                var historyParams = {};

                // トークンを払い出した場合はSTRIPE顧客IDを作成する
                if (result != null) {
                    console.log('result', result)
                    customerParams['customer_data'] = {};
                    customerParams['metadata'] = {};
                    customerParams['customer_data']['name'] = this.name;
                    customerParams['customer_data']['phone'] = this.tel;
                    customerParams['customer_data']['email'] = this.mail;
                    customerParams['customer_data']['stripe_token'] = result.token.id;
                    customerParams['metadata']['uid'] = this.user_info.uid;
                    customerParams['metadata']['token_type'] = 0;
                    customerParams['shop_id'] = String(this.user_info.shop_id);
                    customerParams['cooperate_type'] = 0;

                    console.log('customerParams', customerParams)
                    console.log('this.user_info', this.user_info)

                    if (
                        this.user_info.stripe_customer_id != null
                        && this.stripe_customer_info != null
                       ) {
                        customerParams['stripe_customer_id'] = this.user_info.stripe_customer_id;
                    }

                    var response = await this.apiCall('regist-customer', customerParams, 'post', 3);

                    console.log('response', response)

                    if (!response) return false;
                    console.log("stripe_customer_id", response.results.stripe_customer_id);
                    this.$set(this, 'stripe_customer_id', response.results.stripe_customer_id);
                    payParams['stripe_customer_id'] = response.results.stripe_customer_id;
                    if (this.keepCard || (!this.keepCard && this.stripe_customer_info != null && !this.stripeForm)) {
                        userParams['stripe_customer_id'] = this.stripe_customer_id;
                    }
                }

                // ストライプ顧客情報登録後 仮購入
                historyParams['t_paid_ticket_id'] = this.item.id
                historyParams['mail'] = this.mail
                historyParams['name'] = this.name
                historyParams['tel'] = this.tel
                historyParams['stripe_customer_id'] = this.stripe_customer_id
                if (result != null) {
                    historyParams['stripe_token'] = result.token.id
                }
                historyParams['stripe_status'] = 0
                historyParams['settlement_flg'] = true // 決済トークンチェックフラグ

                console.log('historyParams', historyParams);
                var pay_info = await this.apiCall('stripe/digitalticket', historyParams, 'post', 0, this.payText2 + '事前処理エラー');
                if (!pay_info || pay_info.status != 100) {
                    this.loading = false
                    // 二重アラートになるためコメントアウト
                    //this.callDialog('決済事前処理エラー', '決済処理の事前処理でエラーとなりました。', 3);
                    return false
                }
                console.log('pay_info', pay_info)

                // 抽選チケットエントリー完了
                var user_update_response

                if (this.item.limit_type == 2) {
                  // ユーザ情報更新
                  if (userParams['stripe_customer_id']) {
                    userParams['name'] = this.name;
                    userParams['mail'] = this.mail;
                    userParams['tel'] = this.tel;
                    user_update_response = await this.apiCall('/user/info', userParams, 'post', 0);
                    if (!user_update_response) {
                        this.loading = false
                        return false
                    }
                  }

                  this.loading = false
                  this.callDialog('エントリーが完了しました', '', 3);
                  this.routerBindTo({name: 'digital_list', query: {shop_id: this.item.shop_id}});
                } else {
                  // 決済実行
                  if (result != null) {
                      payParams['stripe_customer_id'] = response.results.stripe_customer_id;
                  } else {
                      payParams['stripe_customer_id'] = this.user_info.stripe_customer_id;
                  }

                  console.log('this.item', this.item)

                  payParams['payment_type'] = 0
                  payParams['amount'] = pay_info.amount
                  payParams['tax_rate'] = pay_info.tax_rate
                  payParams['currency'] = pay_info.currency
                  payParams['description'] = pay_info.title // 旧サービス同様titleを使用
                  payParams['shop_id'] = String(this.user_info.shop_id)
                  payParams['user_id'] = String(this.user_info.id)
                  payParams['order_id'] = String(pay_info.order_id)
                  payParams['cooperate_type'] = pay_info.cooperate_type
                  payParams['order_type'] = pay_info.order_type

                  var res

                  if (pay_info.type == 1 || pay_info.type == 2 || (pay_info.type == 3 && pay_info.subscription_type == 2)) {
                      res = await this.apiCall('/create-charge', payParams, 'post', 3, '決済エラー')
                      console.log('res', res);
                  } else if (pay_info.type == 3 && pay_info.subscription_type == 1) {
                      payParams['name'] = pay_info.title
                      payParams['type'] = 'service'
                      payParams['interval'] = pay_info.interval
                      payParams['interval_count'] = pay_info.interval_count
                      payParams['nickname'] = pay_info.title
                      payParams['usage_type'] = 'licensed'
                      res = await this.apiCall('/create-subscription', payParams, 'post', 3, '決済エラー')
                      console.log('res', res);
                  }

                  if (!res || res.status_cd != 200) {
                      this.loading = false
                      userParams['stripe_success_flg'] = 0
                      // 二重アラートになるためコメントアウト
                      //this.callDialog('決済エラー', '決済処理でエラーとなりました。', 3)
                      historyParams['stripe_status'] = 2
                      return false
                  } else {
                      historyParams['stripe_status'] = 1
                      historyParams['stripe_id'] =  res.results.stripe_id
                  }

                  // 決済実行結果登録(本購入)
                  var hisotry_response = await this.apiCall('stripe/digitalticket', historyParams, 'post', 0, '決済確定処理エラー');
                  if (!hisotry_response || hisotry_response.status != 100) {
                      this.loading = false
                      // 二重アラートになるためコメントアウト
                      // this.callDialog('決済確定処理エラー', '決済確定処理でエラーとなりました。', 3);
                      return false
                  }

                  console.log('hisotry_response', hisotry_response)

                  // ユーザ情報更新
                  userParams['name'] = this.name;
                  userParams['mail'] = this.mail;
                  userParams['tel'] = this.tel;
                  if (!userParams['stripe_customer_id']) {
                    if (this.keepCard || (!this.keepCard && this.stripe_customer_info != null && !this.stripeForm)) {
                        userParams['stripe_customer_id'] = this.stripe_customer_id;
                    }
                  }
                  user_update_response = await this.apiCall('/user/info', userParams, 'post', 0);
                  if (!user_update_response) {
                      this.loading = false
                      // 二重アラートになるためコメントアウト
                      //this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
                      return false
                  }

                  this.loading = false
                  this.callDialog('支払いが完了しました', '', 3);
                  this.routerBindTo({name: 'digital_list', query: {shop_id: this.item.shop_id, is_bought: 1}});

                  // 決済トークン更新
                  let settlementTokenUpdate = await this.settlementTokenUpdate()
                  if (settlementTokenUpdate) {
                    this.init = this.storageGet('*')
                  } else {
                    this.loading = false
                    return false
                  }
              }

            } catch(e) {
                this.loading = false
                this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
                console.log(e)
            }
        }
    },
    async makeStripeForm (digital_ticket) {
        // stripe カード入力フォーム作成
        this.stripe = await loadStripe(digital_ticket.stripe_public_key)
        const elements = this.stripe.elements({locale: 'ja'})

        var style = {
          base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        };

        this.card = elements.create('cardNumber', {style: style});
        var cardExpiry = elements.create('cardExpiry', {style: style});
        var cardCvc = elements.create('cardCvc', {style: style});

        this.card.mount('#card-number-element');
        cardExpiry.mount('#card-expiry-element');
        cardCvc.mount('#card-cvc-element');

        //this.card = elements.create("card");
        //this.card.mount("#card-element");

        this.card.addEventListener('change', ({error}) => {
          const displayError = this.$refs.cardErrors
          if (error) {
            displayError.textContent = error.message;
          } else {
            displayError.textContent = '';
          }
        });

        // Handle real-time validation errors from the card Element.
        cardExpiry.addEventListener('change', function(event) {
          var displayError = document.getElementById('cardExpiry-errors');
          var displayTotalError = document.getElementById('cardTotal-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
            displayTotalError.textContent = '';
          }
        });

        // Handle real-time validation errors from the card Element.
        cardCvc.addEventListener('change', function(event) {
          var displayError = document.getElementById('cardCvc-errors');
          var displayTotalError = document.getElementById('cardTotal-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
            displayTotalError.textContent = '';
          }
        });
    }

  },

  async created() {
    this.getUrlParam()
    this.init = this.storageGet('*')

    try{
      // デジタルチケット詳細API
      var digital_ticket_params = { params:{
        'ticket_id': this.init.ticket_id,
      }}

      var digital_ticket = await this.apiCall('/digitalticket/detail', digital_ticket_params);
      console.log('digital_ticket', digital_ticket)
      if (!digital_ticket) return false;
      this.$set(this, 'item', digital_ticket);
      // 抽選タイプ
      if (digital_ticket.limit_type == 2) {
        this.payText = 'エントリー';
        this.payText2 = 'エントリー';
      }

      // ユーザ情報取得
      var user_info_params = { params:{}}
      var user_info = await this.apiCall('/user/info', user_info_params);
      if (!user_info) return false;
      console.log("user_info", user_info);
      this.$set(this, 'user_info', user_info);

      this.name = user_info.name
      this.tel = user_info.tel
      this.mail = user_info.mail

      // ストライプ顧客情報がある場合は、STRIPEから顧客情報を取得
      if (user_info.stripe_customer_id != null) {

          this.stripeForm = false;
          this.stripeInfo = true;

          var customerParams = {};
          customerParams['stripe_customer_id'] = user_info.stripe_customer_id;
          customerParams['shop_id'] = String(user_info.shop_id);
          customerParams['cooperate_type'] = 0;

          var stripe_customer_info = await this.apiCall('retrieve-customer', customerParams, 'post', 3);
          if (!stripe_customer_info) return false;
          console.log("stripe_customer_info", stripe_customer_info.results);
          this.$set(this, 'stripe_customer_info', stripe_customer_info.results);
          this.$set(this, 'stripe_customer_id', user_info.stripe_customer_id);
      } else {

          this.stripeForm = true;
          this.stripeInfo = false;

          this.makeStripeForm(digital_ticket);
      }

    } catch(e) {
        this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
        console.log(e)
    }
  }

};
</script>

<style scoped>
.lh {
  line-height: 26px;
}
.bordered {
  border: 1px solid #000 !important;
}
.balloon1 {
  position: relative;
  display: block;
  margin: 1.5em auto;
  padding: 2px 20px;
  min-width: 120px;
  max-width: 100%;
  width: 60%;
  font-size: 14px;
  color: #fff;
  background: #35D4CA;
  border-radius: 20px;
}
.balloon1.sub {
  background: #9DD435;
}

.balloon1:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border: 10px solid transparent;
  border-top: 10px solid #35D4CA;
}

.balloon1.sub:before {
  border-top: 10px solid #9DD435;
}

.balloon1 p {
  margin: 0;
  padding: 0;
  text-align: center;
}



.titlebar {
  background-color: #E4E4E4;
  padding: 5px 20px;
  font-size: 14px;
  color: #333333;
  margin-bottom: 15px;
}

.tickettitle {
  font-size: 20px;
}

.checkboxstyle {
  font-size: 11px !important;
}
.footinfo {
  background-color: #595757;
  color: #fff;
}

/* Apple Pay UI FROM */
.apple-pay-button-with-text {
  display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 60px;
    box-sizing: border-box;
    width: 70%;
    min-height: 32px;
    max-height: 64px;
    background-image: -webkit-named-image(apple-pay-logo-white);
    margin-left: 15%;
    text-decoration: none;
    font-size: 1.5rem;
    line-height: 1.4;
    outline: 0;
    border: 0px;
    cursor: pointer;
    box-shadow: 0 0 6px rgba(0,0,0,0.3);
}

.apple-pay-button-with-buy {
  -apple-pay-button-type: buy;
}

.apple-pay-button-with-setup {
  -apple-pay-button-type: set-up;
}

.apple-pay-button-with-text > * {
    /*display: none;*/
}

.hidden {
  display:none;
}
.entry_dialogue_title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 0 0 15px;
}
.entry_dialogue_text {
    font-size: 13px;
    line-height: 20px;
    color: #333333;
}

/* Apple Pay UI TO */

.dl-ticket-kessai dt {
  font-size: 13px;
  color: rgb(157, 157, 157);
  font-weight: 700 !important;
}
#card-number-element, #card-expiry-element, #card-cvc-element {
  border-style: solid;
  border-width: 0 0 thin 0;
  border-color: rgba(0, 0, 0, 0.42);
  margin-bottom: 40px;
}
.checkfont >>> label {
  font-size: 11px;
}
</style>
